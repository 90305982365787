<template>
  <div class="live-page">
    <div class="main">
      <div class="left">
        <div class="board">
          <div class="count_down" v-if="isCountDown">倒计时：{{countDown[0]}}:{{countDown[1]}}</div>
          <div class="course_desc" v-if="handleLive === 'private'">
            <div class="desc_title">{{courseDesc.title}}</div>
            <div class="desc_time">{{courseDesc.time}}</div>
          </div>
          <div class="course_desc_training" v-if="handleLive === 'training'">
            <div class="desc_title">
              <div>{{liveData.category}}{{titleName}}</div>
              <i v-if="attendState === '上课中'"></i>
              <span>{{attendState === '上课中' ? '上课中' : ''}}</span>
            </div>
            <div class="desc_time">{{courseDesc.time}}</div>
          </div>
          <div class="netWorkQuality" v-if="handleLive === 'training' && attendState === '上课中'">
            <div class="upload">
                <div>上行</div>
                <div class="qualityList">
                    <template v-if="upQuality === 0">
                        <div class="item item-one"></div>
                        <div class="item item-two"></div>
                        <div class="item item-three"></div>
                        <div class="item item-four"></div>
                        <div class="item item-five"></div>
                    </template>
                    <template v-else>
                        <div class="item item-one" :class="upQuality<5?'item-highlight':''"></div>
                        <div class="item item-two" :class="upQuality<4?'item-highlight':''"></div>
                        <div class="item item-three" :class="upQuality<3?'item-highlight':''"></div>
                        <div class="item item-four" :class="upQuality<2?'item-highlight':''"></div>
                        <div class="item item-five" :class="upQuality<=1?'item-highlight':''"></div>
                    </template>
                </div>
            </div>
            <div class="download">
                <div>下行</div>
                <div class="qualityList">
                    <template v-if="downQuality === 0">
                        <div class="item item-one"></div>
                        <div class="item item-two"></div>
                        <div class="item item-three"></div>
                        <div class="item item-four"></div>
                        <div class="item item-five"></div>
                    </template>
                    <template v-else>
                        <div class="item item-one" :class="downQuality<5?'item-highlight':''"></div>
                        <div class="item item-two" :class="downQuality<4?'item-highlight':''"></div>
                        <div class="item item-three" :class="downQuality<3?'item-highlight':''"></div>
                        <div class="item item-four" :class="downQuality<2?'item-highlight':''"></div>
                        <div class="item item-five" :class="downQuality<=1?'item-highlight':''"></div>
                    </template>
                </div>
            </div>
        </div>
          <div :class="cutPattern === 1 ? 'videoTitle' : 'not-begin'" v-if="classStatus == 0">
            <div class="tip" v-if="leaveTime!==0" v-html="leaveTime">
            </div>
            <div class="tip" v-else>
              等待老师开课
            </div>
            <div class="count-down">
            </div>
          </div>
          <div :class="cutPattern === 1 ? 'videoTitle' : 'end-class'" v-else-if="classStatus == 2">
            <div class="tip">
              课程直播已结束
            </div>
          </div>
          <!-- 白板 -->
          <Board v-show="cutPattern === 2" :littleVideoWindowShow="littleVideoWindowShow" @openBigVideo="openBigVideo" :attendState="attendState"></Board>
          <!-- 视频 -->
          <!-- <Dialog v-show="cutPattern === 1" :liveData="liveData"></Dialog> -->
          <RTC v-show="isTraining || isVideoCall"  @closeBigVideo="closeBigVideo" :liveData="liveData" :cutPattern="cutPattern" :bigVideoWindowShow="bigVideoWindowShow"></RTC>
          <div class="devicetest" v-if="isTestDevice === false ? false : isShowdeviceTest">
            <devicetest 
              @closeDeviceTest="closeDeviceTest"
              @spreadActiveCameraDeviceId="spreadActiveCameraDeviceId"
              @spreadActiveMicDeviceId="spreadActiveMicDeviceId"
              @spreadActiveSpeakerDeviceId="spreadActiveSpeakerDeviceId"
              ></devicetest>
          </div>
        </div>
      </div>
      <div class="right">
        <!-- <div class="rtc" ref="rtcRef"  v-inserted="" :style="bigVideoWindowShow === true ? '' : 'display: none !important'"> -->
        <!-- </div> -->
        <div class="im" :class="isVideoCall?'':'full_chat'">
          <IM @loadMoreMsg="getHistory" @showMessageInBox="handleMsgToBox"></IM>
        </div>
      </div>
    </div>
    <div class="footer-box">
      <Footer
        ref="footerRef"
        @quitClassroom="quitClassroom" 
        @cutDeviceTest="cutDeviceTest"
        ></Footer>
    </div>
    <el-dialog
        title=""
        :visible.sync="isShowBigPic"
        append-to-body
        width="70%">
      <div><img width="100%" :src="bigImgSrc" alt=""></div>
    </el-dialog>
  </div>
</template>
<script>
  import LiveJs from './Live.js'

  export default LiveJs
</script>

<style lang="less" scoped>
  .course_desc_training {
    display: flex;
      position: absolute;
      top: 20px;
      left: 30px;
      display: flex;
      flex-direction: column;
      color: #fff;
      // align-items: center;
      z-index: 99;
      .desc_title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 2px;
        i {
          display: inline-block;
          width: 4px;
          height: 4px;
          background-color: red;
          border-radius: 50%;
          margin: 0 6px;
        }
        span {
          font-size: 12px;
          font-weight: 400;
          color: red
        }
      }

      .desc_time {
        font-size: 12px;
        color: #ccc;
        text-align: left !important;
      }
  }
  .netWorkQuality {
    position: absolute;
    top: 21px;
    right: 60px;
    z-index: 999;
    color: #fff;
    font-size: 14px;
    display: flex;

    .upload, .download {
        display: flex;
        align-items: center;
        margin-right: 10px;
    }

    .qualityList {
        display: flex;
        align-items: baseline;
        margin-left: 6px;

        .item {
            background: #fff;
            margin-right: 2px;
            width: 2px;
        }

        .item-highlight {
            background: #29D087;
        }

        .item-one {
            height: 2px;
        }

        .item-two {
            height: 4px;
        }

        .item-three {
            height: 6px;
        }

        .item-four {
            height: 8px;
        }

        .item-five {
            height: 10px;
        }
    }
  }
  .devicetest {
    z-index: 9999;
    position: absolute;
    left: 25%;
    top: 50px;
    box-shadow: 0px 0px 13px #ccc;
  }

  .live-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 1000;
      
    & > .main {
      width: 100%;
      height: calc(100% - 38px);
      background: #575757;
      
      & > .left {
        width: 75%;
        height: 100%;
        min-width: 720px;
        float: left;

        & > .board {
          width: 100%;
          height: 100%;
          position: relative;
          background: #575757;
          .count_down {
            position: absolute;
            top: 20px;
            left: 50%;
            color: #fff;
            transform: translateX(-50%);
          }
          .course_desc {
            display: flex;
            
            position: absolute;
            top: 20px;
            right: 30px;
            display: flex;
            color: #fff;
            align-items: center;
            z-index: 99;

            .desc_title {
              font-weight: bold;
              font-size: 16px;
              margin-bottom: 2px;
              margin-right: 20px;
            }

            .desc_time {
              font-size: 12px;
              color: #ccc;
            }
          }


          & > .not-begin, .end-class {
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            font-size: 22px;
            z-index: 11;
            background: rgba(0, 0, 0, 0.5);
            position: relative;
          }

          & > .videoTitle {
            width: 100%;
            height: 100%;
            color: #fff;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 22px;
            z-index: 11;
            // background: rgba(0, 0, 0, 0.5);
            position: relative;
            .tip {
              margin-top: 10px;
            }
          }
        }
      }

      & > .right {
        width: 25%;
        min-width: 240px;
        height: 100%;
        float: left;

        & > .rtc {
          z-index: 999 !important;
          display: inline-block !important;
          position: absolute !important;
          top: 120px;
          right: 0px;
          width: 300px;
          height: 500px;
          background: #fff;
          padding-top: 30px;
          border-radius: 4px;
          box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
          overflow: hidden;
        }

        & > .im {
          width: 100%;
          // height: calc(100% - 25vw * 3 / 4);
          height: 100%;
          background: #fff;
        }

        .full_chat {
          height: 100%;
        }
      }
    }

    & > .footer-box {
      width: 100%;
      height: 38px;
      background: #000;
      position: fixed;
      left: 0;
      bottom: 0;
      z-index: 999;
    }

    /deep/ .timer {
      font-size: 42px;
      color: #1FCA8F;
    }
  }
</style>
