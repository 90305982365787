import { mapState } from 'vuex'

export default {
  props: {
    littleVideoWindowShow: {
      type: Boolean
    },

    attendState: {
      type: String
    }
  },
  data () {
    return {}
  },
  computed: {
    ...mapState({
      classStatus: state => state.live.classStatus,
      shareStatus: state => state.live.shareStatus,
      isShowPaintBox: state => state.board.isShowPaintBox,
    })
  },
  methods: {
    videoWindowBtn() {
      this.$emit('openBigVideo', true)
    }
  },
  watch: {
  }
}
