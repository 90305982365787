var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{directives:[{name:"inserted",rawName:"v-inserted"}],class:_vm.cutPattern === 1 && _vm.isShowVideo ? 'video_module' : 'rtc',style:(_vm.cutPattern === 1 && _vm.isShowVideo
        ? 'left: 0 !important; right: 0 !important; top: 0 !important;'
        : _vm.bigVideoWindowShow === true
        ? ''
        : 'display: none !important')},[_c('div',{staticClass:"video_wrap",attrs:{"id":"video_wrap"}},[_c('div',{staticClass:"off",style:(_vm.cutPattern === 1 && _vm.isShowVideo ? 'display: none' : ''),on:{"click":_vm.offBtn}},[_c('div'),_c('span',[_vm._v("|")]),_c('span',[_vm._v("视频窗口")])]),_c('div',{class:_vm.cutPattern === 1 && _vm.isShowVideo ? 'self-video' : ''},[_c('div',{class:_vm.cutPattern === 1 && _vm.isShowVideo ? 'me_box' : ''},[_c('div',{staticClass:"local_video_wrapper",class:_vm.cutPattern === 1 && _vm.isShowVideo
                ? 'local_video_wrapper_video'
                : _vm.viewType === 1
                ? 'small'
                : 'big',attrs:{"id":"local_video_wrapper"},on:{"click":_vm.changeView}},[_c('div',{staticClass:"local_video"}),_c('div',{staticClass:"local_video-default",class:_vm.hasLocalVideo ? 'hidden' : '',style:(_vm.cutPattern === 1 ? 'background: #333 !important' : '')},[_c('i',{staticClass:"iconfont"},[_c('div',{class:_vm.cutPattern === 1 && _vm.isShowVideo
                      ? 'video_img'
                      : _vm.viewType === 1
                      ? 'header_img'
                      : 'big_header_img'},[(_vm.studentInfo && _vm.studentInfo.avatar)?_c('img',{attrs:{"src":_vm.studentInfo && _vm.studentInfo.avatar,"alt":""},on:{"~error":function($event){return _vm.moveErrorImg.apply(null, arguments)}}}):_c('img',{attrs:{"src":require("@/assets/img/common/defaultpic-S@3x.png"),"alt":""}})])])])])]),_c('div',{class:_vm.cutPattern === 1 && _vm.isShowVideo ? 'teacher_box' : ''},[_c('div',{staticClass:"student_video_wrapper",class:_vm.cutPattern === 1 && _vm.isShowVideo
                ? 'student_video_wrapper_video'
                : _vm.viewType === 2
                ? 'small'
                : 'big',attrs:{"id":"student_video_wrapper"},on:{"click":_vm.changeView}},[_c('div',{staticClass:"remote_video-default",class:_vm.teacherStream && _vm.teacherStream.hasVideo() ? 'hidden' : '',style:(_vm.cutPattern === 1 && _vm.isShowVideo
                  ? 'background: #333 !important'
                  : '')},[_c('i',{staticClass:"iconfont"},[_c('div',{class:_vm.cutPattern === 1 && _vm.isShowVideo
                      ? 'video_img'
                      : _vm.viewType === 2
                      ? 'header_img'
                      : 'big_header_img'},[(
                      _vm.liveData && _vm.liveData.teacher && _vm.liveData.teacher.avatar
                    )?_c('img',{attrs:{"src":_vm.liveData && _vm.liveData.teacher && _vm.liveData.teacher.avatar,"alt":""},on:{"~error":function($event){return _vm.moveErrorImg.apply(null, arguments)}}}):_c('img',{attrs:{"src":require("@/assets/img/common/defaultpic-S@3x.png"),"alt":""}})])])]),_c('div',{staticClass:"remote_video"})])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }