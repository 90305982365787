<template>
  <div>
    <div id="camera-video" class="camera-video"></div>
  </div>
</template>

<script>
import TRTC from "trtc-js-sdk";
export default {
  name: "",
  components: {},
  props: {
    activeCameraDeviceId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      localStream: null,
    };
  },
  computed: {},
  watch: {
    activeCameraDeviceId: {
      handler(newVal) {
        this.handleCameraChange(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {},
  methods: {
    async initStream(cameraID) {
      this.localStream = TRTC.createStream({
        video: true,
        audio: false,
        cameraId: cameraID,
      });
      await this.localStream.initialize();
      this.localStream.play("camera-video");
    },
    handleCameraChange(deviceId) {
      if (this.localStream) {
        this.localStream.switchDevice("video", deviceId);
      } else {
        this.initStream(deviceId);
      }
    },
  },
};
</script>

<style lang='less' scoped>
</style>