<template>
  <div>
    <div
      :class="cutPattern === 1 && isShowVideo ? 'video_module' : 'rtc'"
      v-inserted
      :style="
        cutPattern === 1 && isShowVideo
          ? 'left: 0 !important; right: 0 !important; top: 0 !important;'
          : bigVideoWindowShow === true
          ? ''
          : 'display: none !important'
      "
    >
      <div id="video_wrap" class="video_wrap">
        <div
          :style="cutPattern === 1 && isShowVideo ? 'display: none' : ''"
          class="off"
          @click="offBtn"
        >
          <div></div>
          <span>|</span><span>视频窗口</span>
        </div>
        <div :class="cutPattern === 1 && isShowVideo ? 'self-video' : ''">
          <div :class="cutPattern === 1 && isShowVideo ? 'me_box' : ''">
            <div
              class="local_video_wrapper"
              id="local_video_wrapper"
              :class="
                cutPattern === 1 && isShowVideo
                  ? 'local_video_wrapper_video'
                  : viewType === 1
                  ? 'small'
                  : 'big'
              "
              @click="changeView"
            >
              <div class="local_video"></div>
              <div
                class="local_video-default"
                :class="hasLocalVideo ? 'hidden' : ''"
                :style="cutPattern === 1 ? 'background: #333 !important' : ''"
              >
                <i class="iconfont">
                  <div
                    :class="
                      cutPattern === 1 && isShowVideo
                        ? 'video_img'
                        : viewType === 1
                        ? 'header_img'
                        : 'big_header_img'
                    "
                  >
                    <img
                      :src="studentInfo && studentInfo.avatar"
                      alt=""
                      @error.once="moveErrorImg"
                      v-if="studentInfo && studentInfo.avatar"
                    />
                    <img
                      src="@/assets/img/common/defaultpic-S@3x.png"
                      alt=""
                      v-else
                    />
                  </div>
                </i>
              </div>
            </div>
          </div>
          <div :class="cutPattern === 1 && isShowVideo ? 'teacher_box' : ''">
            <div
              class="student_video_wrapper"
              id="student_video_wrapper"
              :class="
                cutPattern === 1 && isShowVideo
                  ? 'student_video_wrapper_video'
                  : viewType === 2
                  ? 'small'
                  : 'big'
              "
              @click="changeView"
            >
              <div
                class="remote_video-default"
                :class="
                  teacherStream && teacherStream.hasVideo() ? 'hidden' : ''
                "
                :style="
                  cutPattern === 1 && isShowVideo
                    ? 'background: #333 !important'
                    : ''
                "
              >
                <i class="iconfont">
                  <div
                    :class="
                      cutPattern === 1 && isShowVideo
                        ? 'video_img'
                        : viewType === 2
                        ? 'header_img'
                        : 'big_header_img'
                    "
                  >
                    <img
                      :src="
                        liveData && liveData.teacher && liveData.teacher.avatar
                      "
                      alt=""
                      @error.once="moveErrorImg"
                      v-if="
                        liveData && liveData.teacher && liveData.teacher.avatar
                      "
                    />
                    <img
                      src="@/assets/img/common/defaultpic-S@3x.png"
                      alt=""
                      v-else
                    />
                  </div>
                </i>
              </div>
              <div class="remote_video"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RTCJS from "./RTC.js";

export default RTCJS;
</script>

<style lang="less" scoped>
.self-video {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  margin-top: 75px;
}
.video_module {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none !important;
  .teacher_box {
    // position: absolute;
    // top: 78px;
    // left: 189px;
    width: calc(22vw + 8.1vh);
    height: calc(37vw + 12.75vh);
    background-color: transparent;
  }
  .me_box {
    // position: absolute;
    // top: 78px;
    // right: 189px;
    width: calc(22vw + 8.1vh);
    height: calc(37vw + 12.75vh);
    background-color: #333;
  }
  .headerImg {
    width: 28.59%;
    height: 17.16%;
    position: absolute;
    left: 50%;
    top: 50%;
    background: #424242;
    border-radius: 8px;
    transform: translate(-50%, -50%);
    z-index: 999;
    img {
      width: 100%;
    }
  }

  .local_video_wrapper,
  .student_video_wrapper {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    .hidden {
      display: none;
    }
  }

  .local_video_wrapper_video,
  .student_video_wrapper_video {
    width: 100% !important;
    height: 100% !important;
  }

  .remote_video {
    width: 100% !important;
    height: 100% !important;
    div {
      background-color: transparent !important;
    }
  }
  .local_video {
    width: 100%;
    height: 100%;
    div {
      background-color: transparent !important;
    }
  }
}

.rtc {
  z-index: 999 !important;
  display: inline-block !important;
  position: absolute !important;
  top: 120px;
  right: -483px;
  width: 300px;
  height: 500px;
  background: #fff;
  padding-top: 30px;
  border-radius: 8px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow: hidden;
}
/deep/ video {
  object-fit: cover !important;
}
.header_img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  transform: translate(-50%, -50%);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
}
.big_header_img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }
}

.video_img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 120px;
  height: 120px;
  border-radius: 8px;
  transform: translate(-50%, -50%);
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
  }
}
.video_wrap {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 8px;
  .off {
    position: absolute;
    top: -22px;
    left: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85px;
    font-size: 12px !important;
    color: rgba(0, 0, 0, 0.6);

    z-index: 999;
    &:hover {
      cursor: pointer;
    }
    div {
      width: 16px;
      height: 3px;
      background-color: #000;
    }
  }

  .big {
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    border-radius: 8px;
    overflow: hidden;
  }

  .small {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 108px;
    height: 176px;
    z-index: 999;
    border-radius: 8px;
    overflow: hidden;
  }

  .remote_video,
  .local_video {
    width: 100%;
    height: 100%;
  }

  .local_video-default {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #424242;
    z-index: 9;
  }

  /deep/ .remote_video,
  .local_video {
    width: 100%;
    height: 100%;

    /deep/ div {
      /deep/ video {
        left: 0;
      }
    }
  }

  .local_video {
    width: 100%;
    height: 100%;

    div {
      video {
        left: 0;
      }
    }
  }

  /deep/ .local_video {
    width: 100%;
    height: 100%;

    /deep/ div {
      /deep/ video {
        left: 0;
      }
    }
  }

  /deep/ .remote_video {
    width: 100%;
    height: 100%;

    /deep/ div {
      /deep/ video {
        left: 0;
      }
    }
  }

  .remote_video {
    width: 100%;
    height: 100%;

    div {
      video {
        left: 0;
      }
    }
  }
  video {
    left: 0;
  }

  .icon-papa-video_off {
    color: #b6b6b6;
  }

  .icon-papa-video_off:before {
    margin-right: 0 !important;
  }
  .iconfont::before {
    margin-right: 0px !important;
  }

  .local_video_wrapper {
    .local_video-default {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #424242;
      z-index: 9;
    }

    .icon-papa-video_off {
      position: absolute;
      top: 50%;
      margin-top: -10px;
      left: 50%;
      margin-left: -10px;
      font-size: 30px;
    }

    .hidden {
      display: none;
    }
  }

  .remote_video-default {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    width: 100%;
    height: 100%;
    background: #1a1a1a;
  }

  .hidden {
    display: none;
  }

  .icon-papa-video_off {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    left: 50%;
    margin-left: -30px;
    font-size: 60px;
    z-index: 9;
  }

  /deep/ .hiddenWrapper {
    visibility: hidden;
  }
}
</style>
