<template>
  <div class="check">
    <div class="title">
      <slot name="title">是否可以清楚的看到自己?</slot>
    </div>
    <div class="btns">
      <button @click="checkTest(false)">否</button>
      <button @click="checkTest(true)">是</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {},
  data() {
    return {
      msg: "Welcome to Your Vue.js App",
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    checkTest(isOk) {
      this.$emit("checkTest", isOk);
    },
  },
};
</script>

<style lang='less' scoped>
.title {
  text-align: center;
  font-size: 14px;
  color: #000;
}
.btns {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  button {
    &:first-child {
      width: 91px;
      height: 28px;
      border-radius: 5px;
      border: 1px solid #29d087;
      background: #fff;
      border-radius: 4px;
      color: #29d087;
      font-size: 12px;
      cursor: pointer;
    }
    &:last-child {
      width: 91px;
      height: 28px;
      border-radius: 5px;
      border: 1px solid #29d087;
      background: #29d087;
      border-radius: 4px;
      color: #fff;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
</style>
