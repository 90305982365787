import * as CustomMsgType from "./constants/customMsgType.js";
import * as Api from "@/api/live/live.js";
import * as Config from "@/config/live.js";

import TIM from "tim-js-sdk";
import TRTC from "trtc-js-sdk";
import TIMUploadPlugin from "tim-upload-plugin";

import $ from "jquery";
import IM from "@/views/Live/modules/chatroom/chatroom.vue";
import RTC from "@/views/Live/modules/RTC/RTC.vue";
import Board from "@/views/Live/modules/Board/Board.vue";
import Dialog from "@/views/Live/modules/RTC/dialog/dialog.vue";
import Footer from "@/views/Live/modules/Footer/Footer.vue";
import devicetest from "@/views/Live/modules/detection/devicetest.vue";

import EXT_IM from "@/views/Live/ext/im.js";
import EXT_RTC from "@/views/Live/ext/rtc.js";
import * as Util from "@/utils/utils";

import { mapActions, mapState } from "vuex";

import { imMixin } from "@/views/Live/mixins/imMixin";
import { rtcMixin } from "@/views/Live/mixins/rtcMixin";
import { boardMixin } from "@/views/Live/mixins/boardMixin";

import { getTime } from "@/utils/utils";
export default {
  mixins: [imMixin, rtcMixin, boardMixin],
  name: "live",
  data() {
    return {
      account: {
        userID: "",
        userSig: "",
      },
      roomID: "",
      status: 0,
      notification: "",
      tic: null,
      tim: null,
      fileInfoList: [],
      liveMode: 0,
      currentTeacher: {},
      leaveTime: "",
      courseDesc: {},
      bigImgSrc: "",
      isShowBigPic: false,
      isShowdeviceTest: true,
      isTestDevice: true,
      bigVideoWindowShow: true,
      littleVideoWindowShow: false,
      switch: false,
      customInfo: {},
      isCountDown: false,
      countDown: [],
      cutPattern: 1,
      rtcDom: null,
      handleLive: "",
      liveData: {},
      titleName: "",
      timerId: "",
      onLineMsg: {},
      onLineTimerId: "",
      noClassSendMsgTimer: null,
    };
  },

  computed: {
    ...mapState({
      authorization: (state) => state.user.authorization,
      classStatus: (state) => state.live.classStatus,
      cameraStatus: (state) => state.live.cameraStatus,
      shareStatus: (state) => state.live.shareStatus,
      videoWrap: (state) => state.live.videoWrap,
      currentUser: (state) => state.live.currentUser,
      userList: (state) => state.live.userList,
      groupInfo: (state) => state.live.groupInfo,
      isVideoCall: (state) => state.live.isVideoCall,
      localStream: (state) => state.rtc.localStream,
      micStatus: (state) => state.live.micStatus,
      videoStatus: (state) => state.live.videoStatus,
      isShowFileListBox: (state) => state.board.isShowFileListBox,
      isShowNotificationBox: (state) => state.live.isShowNotificationBox,
      teacherStream: (state) => state.rtc.teacherStream,
      userId: (state) => state.user.authorization.uuid,
      nextMsgSeq: (state) => state.im.nextMsgSeq,
      trtcClient: (state) => state.rtc.trtcClient,
      studentStream: (state) => state.rtc.studentStream,
      isIMSdkReady: (state) => state.im.isIMSdkReady,
      upQuality: (state) => state.live.uploadQuality,
      downQuality: (state) => state.live.downloadQuality,
    }),

    attendState() {
      if (this.classStatus == "1") {
        return "上课中";
      } else if (this.classStatus == "2") {
        return "已结束";
      } else {
        return "未开始";
      }
    },

    isTraining() {
      if (this.$route.fullPath.indexOf("/training/") > -1) {
        return true;
      } else {
        return false;
      }
    },
  },

  mounted() {
    this.isTestDevice =
      JSON.parse(localStorage.getItem("isTestDevice")) == null
        ? "true"
        : JSON.parse(localStorage.getItem("isTestDevice"));
    this.handleLive = this.$route.path.includes("private")
      ? "private"
      : "training";
    Util.papaFundeBug(this.$route.params.id, "学生进入教室", {});
    window.TIM = TIM;
    window.TRTC = TRTC;
    if (Util.getBrower().name === "chrome" && Util.getBrower().version < 53) {
      this.$alert(
        "您的浏览器不支持直播课程 建议下载最新版Chrome浏览器",
        "提示",
        {
          confirmButtonText: "确定",
          callback: () => {
            window.open("https://www.google.cn/chrome/");
          },
        }
      );
    }

    // 是否打印 TRTC SDK log
    TRTC.Logger.setLogLevel(TRTC.Logger.LogLevel.ERROR);
    TRTC.checkSystemRequirements().then((result) => {
      if (!result) {
        this.$alert(
          "您的浏览器不支持直播课程 建议下载最新版Chrome浏览器",
          "提示",
          {
            confirmButtonText: "确定",
            callback: () => {
              window.open("https://www.google.cn/chrome/");
            },
          }
        );
      }
    });
    this.showBigPic();
    this.init();
    this.$nextTick(() => {
      this.rtcDom = this.$refs.rtcRef;
      console.log(this.rtcDom);
    });

    this.customEvent();
    this.adaptHight();
    this.startFixTimerInterval()
  },

  methods: {
    async init() {
      console.log(106);
      //  this.liveMode = this.$route.path.includes('private') ? this.constant.LIVE_MODE.PRIVATE : this.constant.LIVE_MODE.CLASS
      if (this.$route.path.includes("private")) {
        this.liveMode = this.constant.LIVE_MODE.PRIVATE;
      } else if (this.$route.path.includes("training")) {
        this.liveMode = this.constant.LIVE_MODE.TRAINING;
      } else {
        this.liveMode = this.constant.LIVE_MODE.CLASS;
      }
      this.setLiveMode(this.liveMode);

      this.account.userID = this.authorization.uuid;
      this.setCurrentUser(this.account.userID);
      this.getLiveDetail(this.$route.params.id);
      this.tim = window.tim = EXT_IM.getIMInstance();
      this.setTim(this.tim);
      if (this.tim) {
        this.tim.registerPlugin({ "tim-upload-plugin": TIMUploadPlugin });
      }
      this.imRemoveEventListener();
      this.imEventListener(this.tim);
      await this.joinClassroom();
      console.log(119);
      if (this.isIMSdkReady) {
        await this.afterJoin();
      } else {
        setTimeout(async () => {
          console.log(124, this.afterJoin);
          await this.afterJoin();
        }, 2000);
      }

      return "init success";
    },

    async joinClassroom() {
      await this.getUserLiveToken().catch((e) => {
        Util.papaFundeBug(this.$route.params.id, JSON.stringify(e));
      });
      await EXT_IM.handleLoginIM({ ...this.account }).catch((e) => {
        Util.papaFundeBug(this.$route.params.id, JSON.stringify(e));
      });
      try {
        await EXT_IM.handleJoinIMGroup(this.tim, this.roomID);
      } catch (e) {
        // 重复进群
        if (e.code === 10013) {
          this.tim
            .quitGroup(this.roomID + "")
            .then(() => {
              EXT_IM.handleJoinIMGroup(this.tim, this.roomID).then(() => {
                console.log(10003, "加入房间成功");
              });
            })
            .catch((e) => {
              console.log("10013 退出房间失败", e);
            });
        } else {
          Util.papaFundeBug(this.$route.params.id, JSON.stringify(e));
        }
      }
      // await this.joinBoard()
      let clientConfig = {
        sdkAppId: parseInt(Config.sdkAppId),
        mode: "rtc",
        userId: this.account.userID,
        userSig: this.account.userSig,
      };
      let client = await EXT_RTC.createRTCClient(clientConfig);
      this.setTrtcClient(client);
      if (localStorage.getItem("classStatus") === "1") {
        this.joinRTCRoom();
      }
      return "success";
    },

    async afterJoin() {
      let msg = {
        type: "c2c",
        targetId: this.currentTeacher.uuid,
        data: CustomMsgType.UsserTellEnter(),
      };
      EXT_IM.sendCustomMessage(msg);
      let memberList = await EXT_IM.getGroupMemberList(this.roomID, 100, 0);
      this.setUserList(memberList);
      let { data, NextMsgSeq } = await EXT_IM.getGroupInfo(this.roomID + "");
      this.setGroupInfo(data.group);
      sessionStorage.getMessageCount = 1; // 循环获取聊天记录（腾讯的聊天记录有时候会拿不到）
      this.setNextMsgSeq(NextMsgSeq);
      this.getHistory();
    },
    //
    showBigPic() {
      let self = this;
      $(".live-page").on("click", ".chat_img", function () {
        let src = $(this).attr("bigimgsrc");
        self.showOriginPic(src);
      });
    },
    getUserLiveToken() {
      return new Promise((resolve, reject) => {
        Api.userSign(
          ({ sign }) => {
            sessionStorage.userSign = sign;
            this.account.userSig = sessionStorage.userSign;
            resolve(sign);
          },
          (e) => {
            reject(e);
          }
        );
      });
    },
    getLiveDetail(id) {
      Api.getLiveDetail(
        id,
        this.liveMode,
        ({ data, server_time }) => {
          console.log(data);
          this.liveData = data;
          let startClassTime = data.started_at;
          let serverTime = server_time;
          let differ = startClassTime - serverTime;
          if (differ > 0) {
            this.leaveTime = this.CountDownFn(differ);
          } else {
            this.leaveTime = 0;
          }

          this.roomID = window.roomID = data.live_room_id;
          this.setRoomID(this.roomID);
          if (data.teacher) {
            this.setCurrentTeacher(data.teacher);
            sessionStorage.currentTeacher = JSON.stringify(data.teacher);
            this.currentTeacher = data.teacher;
          }
          console.log(data.is_video_call);
          this.setIsVideoCall(data.is_video_call);
          if (this.liveMode !== this.constant.LIVE_MODE.CLASS) {
            this.courseDesc = {
              title: data.teacher ? data.teacher.name + "1v1" : data.title,
              time: `${Util.formatMoment(
                data.started_at,
                "MM月DD日"
              )} ${Util.formatMoment(
                data.started_at,
                "HH:mm"
              )}-${Util.formatMoment(data.ended_at, "HH:mm")}`,
            };
          } else {
            this.courseDesc = {
              title: data.title,
              time: `${Util.formatMoment(
                data.started_at,
                "MM月DD日"
              )} ${Util.formatMoment(
                data.started_at,
                "HH:mm"
              )}-${Util.formatMoment(data.ended_at, "HH:mm")}`,
            };
          }
          if (this.$route.path.includes("/training/")) {
            this.getTrainingType(this.liveData.type);
          }
        },
        () => {
          // window.close()
        }
      );
    },

    getTrainingType(type) {
      let params = {
        type,
      };
      Api.getTypeApi(params, (res) => {
        console.log(res);
        let typeArr = res.speaking_appointment.types;
        console.log(typeArr, this.liveData.type);
        const newType = typeArr.find((item) => item.id === this.liveData.type);
        this.titleName = newType.name;
      });
    },

    /*
     * 课程未开始倒计时
     * */
    CountDownFn(time) {
      let intDiff = time;
      let timer = window.setInterval(() => {
        let minute = 0;
        if (intDiff > 0) {
          minute = Math.ceil(intDiff / 60);
        }
        if (minute === 0) {
          this.leaveTime = 0;
        } else {
          this.leaveTime = ` 距离课程开始还有 <span class="timer">${minute}</span>分钟`;
        }
        if (intDiff == 0) {
          clearInterval(timer);
        }
        intDiff--;
      }, 1000);
    },

    joinRTCRoom() {
      return this.trtcClient
        .join({ roomId: parseInt(this.roomID) })
        .then(() => {
          this.initTRTCEvent();
          this.startRTC()
        })
        .catch((e) => {
          console.log(e);
          Util.papaFundeBug(this.$route.params.id, JSON.stringify(e), {});
        });
    },

    initData() {
      this.msgs = [];
    },

    /**
     * 退出课堂
     */
    quitClassroom() {
      if (!this.roomID) {
        window.close();
        return;
      } else {
        this.tim
          .quitGroup(this.roomID)
          .then(() => {
            return this.tim.logout();
          })
          .then(() => {
            this.clearClassInfo();
            window.close();
          })
          .catch((e) => {
            console.log(e);
            this.tim.logout().then(() => {
              window.close();
            });
          });
      }
    },

    clearClassInfo() {
      //设备信息
      this.remoteVideos = {};
      this.enableCamera = true;
      this.enableMic = true;
      this.isPushing = 0;
      this.isPushCamera = 0;

      //房间
      this.roomID = null;

      this.fileInfoList = [];
      this.currentFileId = null;
    },

    onSwitchFile(file) {
      this.teduBoard.switchFile(file.fid);
    },

    beforeUnloadFn(e) {
      e = e || window.event;
      if (e) {
        e.returnValue = "您是否确认离开此页面-您输入的数据可能不会被保存";
      }
      return "您是否确认离开此页面-您输入的数据可能不会被保存";
    },
    getUserProfileInUserList(userAccount) {
      let user = {};
      this.userList.forEach((ele) => {
        if (ele.userID === userAccount) {
          user = ele;
        }
      });
      console.log(user);
      return user;
    },

    getHistory() {
      let beginMsgSeq = this.nextMsgSeq;
      let conversationID = "GROUP" + this.roomID;
      EXT_IM.getHistoryMsg(this.tim, conversationID, beginMsgSeq).then(
        (res) => {
          let list = res.data;
          this.setNextMsgSeq(list.nextReqMessageID);
          this.setIsCompleted(list.isCompleted);
          EXT_IM.dealHistoryMsg(list.messageList).then((msgList) => {
            if (msgList.length === 0 && !list.isCompleted) {
              return this.getHistory();
            }
            if (msgList.length > 0) {
              let list = [];
              msgList.forEach((ele, index) => {
                let user = ele.user;
                let text = ele.text;
                let type = ele.type;
                let account = user.nick || user.account;
                if (user.account === this.userId) {
                  account = "personal";
                }
                let time = ele.time;
                let obj = {
                  send: account,
                  content: text,
                  type: type,
                  avatar: user.avatar,
                  identity: user.identity,
                  status: status,
                  time: time,
                };
                list.push(obj);
              });
              list.forEach((item) => {
                if (item.send === this.liveData.teacher.uuid) {
                  item.avatar = this.liveData.teacher.avatar;
                  item.send = this.liveData.teacher.name;
                }
              });
              this.addHistoryMsg(list);
            }
          });
        }
      );
    },
    showOriginPic(src) {
      this.bigImgSrc = src;
      this.isShowBigPic = true;
    },

    closeDeviceTest() {
      this.isShowdeviceTest = false;
    },

    closeBigVideo(val) {
      this.bigVideoWindowShow = val;
      this.littleVideoWindowShow = true;
    },

    openBigVideo(val) {
      this.bigVideoWindowShow = val;
      this.littleVideoWindowShow = false;
    },

    customEvent() {
      if (this.customInfo?.payload?.data === undefined) return;
      let obj = JSON.parse(this.customInfo?.payload?.data);
      if (this.handleLive === "training") {
        if (obj.duration >= 0) {
          if (obj.duration > 0) {
            const res = getTime(obj.duration);
            console.log(res);
            this.countDown = res;
            this.isCountDown = true;
          }
          this.setCountDownFn(obj.duration);
        }
      }

      if (obj.mode === "board") {
        // 白板
        this.cutPattern = 2;
        let msgCon = `老师已切换为白板模式`;
        this.handleMsgToBox(this.customInfo, msgCon, "notify"); //聊天框发送切换状态
      }

      if (obj.mode === "video") {
        // 视频
        this.cutPattern = 1;
        let msgCon = `老师已切换为视频模式`;
        this.handleMsgToBox(this.customInfo, msgCon, "notify"); //聊天框发送切换状态
      }
    },

    // 倒计时函数
    setCountDownFn(time) {
      if (time === 0) {
        this.isCountDown = false;
        clearInterval(this.timerId);
        return;
      }
      if (time > 0) {
        time -= 1;
        clearInterval(this.timerId);
        this.timerId = setInterval(() => {
          let _time = time--;
          console.log(_time);
          if (_time < 0) {
            clearInterval(this.timerId);
            this.isCountDown = false;
            return;
          }
          const res = getTime(_time);
          console.log(res);
          this.countDown = res;
        }, 1000);
      }
    },
    // 设备检测摄像头id
    spreadActiveCameraDeviceId(val) {
      console.log(val);
      this.$refs.footerRef.activeCamerasId = val;
    },

    // 设备检测麦克风id
    spreadActiveMicDeviceId(val) {
      console.log(val);
      this.$refs.footerRef.activeMicDeviceId = val;
    },

    // 设备检测扬声器id
    spreadActiveSpeakerDeviceId(val) {
      console.log(val);
      this.$refs.footerRef.activeSpeakerDeviceId = val;
    },

    // 适配浏览器高度
    adaptHight() {
      if (window.innerHeight < 680) {
        document.querySelector(".devicetest").style =
          "transform: scale(0.8); bottom: -25px;";
      }
    },

    cutDeviceTest() {
      this.isShowdeviceTest = true;
      this.isTestDevice = true;
    },

    handleOnLine(msg) {
      this.onLineMsg = msg;
      this.setIsOnLone(true);
    },

    startFixTimerInterval() {
      this.noClassSendMsgTimer = setInterval(() => {
        let msg = {
          data: CustomMsgType.MsgONLine(),
          type: "group",
        };
        EXT_IM.sendCustomMessage(msg);
      }, 5000);
    },
    ...mapActions([
      // IM
      "setTim",
      "addMsg",
      "setImSdkStatus",

      // Board
      "setCurrentFile",
      "setIsShowPaintBox",

      // RTC
      "setTrtcClient",
      "setRemoteStream",
      "setVoiceStatus",
      "addStudentStream",
      "setStudentStream",

      // live
      "setRoomID",
      "clearLists",
      "setUserRole",
      "setIsConnect",
      "setMicStatus",
      "setFileList",
      "setUserMute",
      "setUserList",
      "setGroupMute",
      "setTeduBoard",
      "setLiveMode",
      "setIsVideoCall",
      "setLocalStream",
      "setGroupInfo",
      "setCurrentUser",
      "setShareStatus",
      "setShareStatus",
      "setVideoStatus",
      "setCameraStatus",
      "setClassStatus",
      "setUserHandStatus",
      "setCurrentTeacher",
      "setUserConnectStatus",
      "addHistoryMsg",
      "setNextMsgSeq",
      "setIsCompleted",
      "setUploadQuality",
      "setDownloadQuality",
      "setIsOnLone",
    ]),
  },
  components: {
    Board,
    Dialog,
    IM,
    RTC,
    Footer,
    devicetest,
  },
  watch: {
    isIMSdkReady() {},

    classStatus: {
      handler: function (newVal) {
        localStorage.setItem("classStatus", newVal);
        if (newVal == "1") {
          // 上课
          if (this.$route.path.includes("/training/")) {
            Api.joinRoom(this.$route.params.id, { type: 3 });
          }
          clearInterval(this.noClassSendMsgTimer);
        } else if (newVal == "2") {
          // 结束
          if (this.$route.path.includes("/training/")) {
            Api.exitRoom(this.$route.params.id);
            // 教师端点下课，学生端关闭摄像头
            this.setLocalStream(null);
          }
          this.startFixTimerInterval()
        } else {
          // 未开始
          if (this.$route.path.includes("/training/")) {
            Api.joinRoom(this.$route.params.id, { type: 2 });
          }
        }
      },
      immediate: true,
    },

    onLineMsg: {
      handler(newVal) {
        if (newVal) {
          clearInterval(this.onLineTimerId);
          this.onLineTimerId = setInterval(() => {
            this.setIsOnLone(false);
          }, 15000);
        }
      },
      immediate: true,
      deep: true,
    },

    $route: {
      handler(newVal) {
        console.log(newVal);
        if (newVal.path.includes("/private/")) {
          this.cutPattern = 2;
        }
      },
      immediate: true,
    },
  },
};
