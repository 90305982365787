<template>
  <div class="footer-module">
    <div class="left">
      <div class="logo">
        <img src="./img/logo.png" alt="">
        <span>v2.3.2</span>
      </div>
      <div class="tools">
        <div class="voice">
          <template v-if="classStatus === 1">
            <div class="tip_box" v-show="classStatus===1&&voiceStatus === 0">
              <div class="tip_con">
                <div class="con">
                  <span class="con_text">点击这里<br>可以打开<br>声音</span></div>
              </div>
              <div class="tip_point"></div>
            </div>
            <i v-if="voiceStatus === 1" class="iconfont icon-papa-volume" @click="toggleVoice(0)"></i>
            <i v-else-if="voiceStatus === 0" class="iconfont icon-papa-volume_off" @click="toggleVoice(1)"></i>
            <el-slider style="width: 105px;margin:0 10px" v-model="voiceNumber" :min=0 :max=100
                       @change="setVoiceNumber"></el-slider>
          </template>
          <template v-else>
            <i class="iconfont icon-papa-volume_off" style="opacity: 0.6;cursor: not-allowed"></i>
            <div>
              <el-slider style="width: 105px;margin:0 10px;opacity: 0.6;cursor: not-allowed" disabled  v-model="voiceNumber" :min=0 :max=100
              ></el-slider>
            </div>
          </template>
        </div>
        <div class="mic" style="margin-left: 10px">
          <template v-if="isTraining || liveMode === 1">
            <div class="tip_box" v-show="classStatus===1&&micStatus === 2 && isShowMicTip && !isShowVoiceTip">
              <div class="tip_con">
                <div class="con">
                  <div class="con_close iconfont icon-papa-closewhite" @click="isShowMicTip=false"></div>
                  <span class="con_text">开始上课<br>请点击这里<br>打开麦克风</span></div>
              </div>
              <div class="tip_point"></div>
            </div>
            <i v-if="micStatus===1" class="iconfont icon-papa-microphone" @click="toggleMic(2)"></i>
            <i v-else-if="micStatus === 2" class="iconfont icon-papa-microphone_off" @click="toggleMic(1)"></i>
            <i v-else class="iconfont icon-microphone_off"></i>
          </template>
          <template v-else>
            <template v-if="isConnect">
              <i v-if="micStatus===1" class="iconfont icon-papa-microphone" @click="toggleMic(2)"></i>
              <i v-else-if="micStatus === 2" class="iconfont icon-papa-microphone_off" @click="toggleMic(1)"></i>
              <i v-else class="iconfont icon-papa-microphone_off"></i>
            </template>
            <template v-else>
              <i class="iconfont icon-papa-microphone_off" style="opacity: 0.6; cursor: not-allowed"></i>
            </template>

          </template>

        </div>
        <div class="video" v-if="isTraining || isVideoCall&&liveMode === 1">
          <div class="tip_box" v-show="classStatus===1&&cameraStatus === 0 && isShowCameraTip && !isShowMicTip">
            <div class="tip_con">
              <div class="con">
                <div class="con_close iconfont icon-papa-closewhite" @click="isShowCameraTip = false"></div>
                <span class="con_text">点击这里<br>可以打开<br>摄像头</span></div>
            </div>
            <div class="tip_point"></div>
          </div>
          <i v-if="cameraStatus === 1 " class="iconfont icon-papa-video" @click="toggleCamera(0)"></i>
          <i v-else-if="cameraStatus === 0" class="iconfont icon-papa-video_off" @click="toggleCamera(1)"></i>
          <i v-else class="iconfont icon-papa-video_off"></i>
        </div>
        <div class="setting">
          <el-popover
              popper-class="devicesPopover"
              placement="top"
              trigger="click"
              v-model="isShowSettingPanel"
          >
            <el-form>
              <el-form-item label="喇叭">
                <el-select v-model="activeSpeakerDeviceId">
                  <el-option v-for="item in speakerList" :key="item.deviceId" :label="item.label"  :value="item.deviceId">
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="麦克风">
                <el-select v-model="activeMicDeviceId">
                  <el-option v-for="item in micDeviceList" :key="item.deviceId" :label="item.label" :value="item.deviceId">
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="摄像头">
                <el-select v-model="activeCamerasId">
                  <el-option v-for="item in micCamerasList" :key="item.deviceId" :label="item.label" :value="item.deviceId">
                    {{ item.label }}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item align="right">
                <el-button type="primary" @click="openTest">设备检测</el-button>
                <el-button type="primary" size="mini" @click="setDevice">确定</el-button>
                <el-button  size="mini" @click="isShowSettingPanel=false">取消</el-button>
              </el-form-item>
            </el-form>
            <i slot="reference" class="iconfont papa-icon-setup">设置</i>
          </el-popover>
        </div>
      </div>
    </div>
    <div class="exit">
      <i class="iconfont icon-papa-index_dropout" @click="exitClass">退出课堂</i>
    </div>
  </div>
</template>

<script>
import FooterJs from './Footer.js'

export default FooterJs
</script>

<style lang="less">
.footer-module {
  width: 100%;
  height: 100%;
  color: #bec7d0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 20px;
  .el-slider__runway.disabled .el-slider__bar{
    background: #29D087;
  }

  & > .left {
    display: flex;
    align-items: center;

    & > .logo {
      width: 146px;
      display: flex;
      align-items: center;
      img {
        width: 75%;
      }
      span{
        margin-left: 5px;
        font-size: 12px;
      }
    }

    & > .tools {
      margin-left: 56px;
      display: flex;
      align-items: center;
      font-size: 14px;

      & > div {
        cursor: pointer;
      }

      i {
        font-size: 14px;
      }

      i:before {
        margin-right: 5px;
      }

      & > .mic, .voice {
        margin-right: 20px;
        color: #29D087;
        width: 120px;
        display: flex;
        align-items: center;
        position: relative;

        & > i:before {
          font-size: 16px;
        }
      }

      & > .mic {
        width: 20px;
      }

      & > .video {
        color: #29D087;
        margin-right: 150px;
        display: flex;
        align-items: center;
        position: relative;

        & > i:before {
          font-size: 18px;
        }
      }

      & > .setting {
        margin-right: 20px;
      }

      & > .begin {
        width: 80px;
      }

      & > .changeShare, .exitShare, .share {
        width: 110px;
        margin-right: 20px;
      }
    }
  }

  & > .exit {
    cursor: pointer;
    color: #29D087;
  }

  .voice-bar {
    width: 105px;
    height: 12px;
    margin-left: 16px;
    position: relative;
    cursor: pointer;

    .line {
      width: 100%;
      height: 2px;
      margin: 5px 0;
      background: #fff;

      .with-color {
        height: 2px;
        width: 50px;
        background: #29d087
      }
    }

    .ball {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #29d087;
      position: absolute;
      top: 1px;
      left: 50px;
    }
  }

  .tip_box {
    position: absolute;
    top: -132px;
    left: 14px;

    .tip_con {
      width: 121px;
      height: 116px;
      border-radius: 50%;
      background: @papaGreen;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 9999 !important;

      .con {
        position: relative;
        width: 100%;
        text-align: center;

        .con_close {
          position: absolute;
          right: -5px;
          top: 0;
          color: #888888;
          opacity: .7;
          background: #fff;
          border-radius: 50%;
          width: 15px;
          height: 15px;
        }
      }
    }

    .tip_point {
      width: 14px;
      height: 12px;
      border-radius: 50%;
      background: @papaGreen;
    }
  }

  .button--disabled {
    opacity: .8;
    cursor: not-allowed;

  }
}
</style>
