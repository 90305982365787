<template>
  <div id="video_wrap" class="video_wrap">
    <div class="teacher_box">
      <div class="local_video_wrapper" id="local_video_wrapper">
        <div class="local_video"></div>
        <div class="headerImg" :class="hasLocalVideo?'hidden':''">
          <img :src="liveData.teacher.avatar" alt="" @error.once="moveErrorImg" v-if="liveData.teacher.avatar">
          <img src="../../../../../assets/img/common/avatar.png" alt="" v-else>
        </div>
      </div>
    </div>
    <div class="me_box">
      <div class="student_video_wrapper" id="student_video_wrapper">
        <div class="remote_video"></div>
        <div class="headerImg" :class="teacherStream&&teacherStream.hasVideo()?'hidden':''">
          <img :src="studentInfo.avatar" alt="" @error.once="moveErrorImg" v-if="studentInfo.avatar">
          <img src="../../../../../assets/img/common/avatar.png" alt="" v-else>
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
  import DialogJs from './dialog'
  export default DialogJs
</script>

<style lang="less" scoped>
  ::v-deep video {
    object-fit: cover !important;
  }
  .video_wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    video {
      object-fit: cover !important;
      background-color: none !important;
    }
    .teacher_box {
      position: absolute;
      top: 90px;
      left: 200px;
      width: 29.17%;
      height: 75.19%;
      background-color: #333;
    }
    .me_box {
      position: absolute;
      top: 90px;
      right: 200px;
      width: 29.17%;
      height: 75.19%;
      background-color: #333;
    }
    .headerImg {
      width: 28.59%;
      height: 17.16%;
      position: absolute;
      left: 50%;
      top: 50%;
      background: #424242;
      border-radius: 8px;
      transform: translate(-50%, -50%);
      z-index: 999;
      img {
        width: 100%;
      }
    }
    .local_video_wrapper,
    .student_video_wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .hidden {
        display: none;
      }
    }
    
    .remote_video {
      width: 100% !important;
      height: 100% !important;
      div {
        background-color: transparent !important;
      }
    }
    .local_video {
      width: 100%;
      height: 100%;      
      div {
        background-color: transparent !important;
      }
    }
  }
</style>