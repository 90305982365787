var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"devicetest"},[_c('i',{staticClass:"iconfont icon-papa-close-small-copy",on:{"click":_vm.close}}),_c('div',{staticClass:"test"},[_vm._v(" 设备检测 "),_c('i',{staticClass:"iconfont icon-papa-renovate",class:_vm.rotateRenovate ? 'renovate' : '',on:{"click":_vm.renovate}})]),_c('div',{staticClass:"facilitys"},[_c('div',{staticClass:"camera"},[_c('div',{staticStyle:{"height":"483px"}},[_c('div',{staticClass:"title_icon"},[(_vm.firstandmin)?_c('i',{staticClass:"iconfont icon-papa-loading3"}):_c('i',{staticClass:"iconfont",class:_vm.deviceState.hasCameraConnect && _vm.checkdevices.camera
                ? 'icon-papa-choose2'
                : 'icon-papa-fasongshibai'})]),_vm._m(0),_c('div',{staticClass:"title_icon"},[_vm._v("摄像头")]),_c('div',[_c('el-select',{attrs:{"size":"small"},on:{"change":_vm.CameraDeviceChangeFn},model:{value:(_vm.activeCameraDeviceId),callback:function ($$v) {_vm.activeCameraDeviceId=$$v},expression:"activeCameraDeviceId"}},_vm._l((_vm.cameraList),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.deviceId,"label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('div',{staticClass:"hint_title"},[_vm._v("您必须授权趴趴教育访问摄像头，才能预览")]),(!_vm.firstandmin)?_c('div',{staticClass:"cameraDetector"},[_c('CameraDetector',{attrs:{"activeCameraDeviceId":_vm.activeCameraDeviceId}})],1):_vm._e()]),(!_vm.isShowCheckCamera)?_c('div',[(_vm.isShowCheck)?_c('devicecheck',{on:{"checkTest":function($event){return _vm.checkTest($event, 'camera')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 是否可以清楚的看到自己? ")]},proxy:true}],null,false,79421978)}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"microphone"},[_c('div',{staticStyle:{"height":"483px"}},[_c('div',{staticClass:"title_icon"},[(_vm.firstandmin)?_c('i',{staticClass:"iconfont icon-papa-loading3"}):_c('i',{staticClass:"iconfont",class:_vm.deviceState.hasMicrophoneConnect && _vm.checkdevices.microphone
                ? 'icon-papa-choose2'
                : 'icon-papa-fasongshibai'})]),_vm._m(1),_c('div',{staticClass:"title_icon"},[_vm._v("麦克风")]),_c('div',[_c('el-select',{attrs:{"size":"small"},on:{"change":_vm.micDeviceChangeFn},model:{value:(_vm.activeMicDeviceId),callback:function ($$v) {_vm.activeMicDeviceId=$$v},expression:"activeMicDeviceId"}},_vm._l((_vm.micDeviceList),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.deviceId,"label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('div',{staticClass:"hint_title"},[_vm._v(" 麦克风出问题了嘛？调节输入音量，随便说点什么搞笑的话试试 ")]),_c('div',[(!_vm.firstandmin)?_c('MicDetector',{attrs:{"activeMicDeviceId":_vm.activeMicDeviceId}}):_vm._e()],1)]),(!_vm.isShowCheckMicrophone)?_c('div',[(_vm.isShowCheck)?_c('devicecheck',{on:{"checkTest":function($event){return _vm.checkTest($event, 'microphone')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" 是否可以看到音量图标跳动? ")]},proxy:true}],null,false,2198722788)}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"trumpet"},[_c('div',{staticStyle:{"height":"483px"}},[_c('div',{staticClass:"title_icon"},[(_vm.firstandmin)?_c('i',{staticClass:"iconfont icon-papa-loading3"}):_c('i',{staticClass:"iconfont",class:_vm.deviceState.hasSpeakerConnect && _vm.checkdevices.trumpet
                ? 'icon-papa-choose2'
                : 'icon-papa-fasongshibai'})]),_vm._m(2),_c('div',{staticClass:"title_icon"},[_vm._v("扬声器")]),_c('div',[_c('el-select',{attrs:{"size":"small"},on:{"change":_vm.SpeakerDeviceChangeFn},model:{value:(_vm.activeSpeakerDeviceId),callback:function ($$v) {_vm.activeSpeakerDeviceId=$$v},expression:"activeSpeakerDeviceId"}},_vm._l((_vm.speakerList),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.deviceId,"label":item.label}},[_vm._v(" "+_vm._s(item.label)+" ")])}),1)],1),_c('div',{staticClass:"hint_title"},[_vm._v("请调节输出音量，点击播放下方的音频试试")]),_c('div',[(!_vm.firstandmin)?_c('SpeakerDetector',{attrs:{"activeSpeakerDeviceId":_vm.activeSpeakerDeviceId}}):_vm._e()],1)]),(!_vm.isShowCheckTrumpet)?_c('div',[(_vm.isShowCheck)?_c('devicecheck',{on:{"checkTest":function($event){return _vm.checkTest($event, 'trumpet')}},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v("是否可以听到声音？")]},proxy:true}],null,false,686657086)}):_vm._e()],1):_vm._e()])]),(!_vm.isShowCheck)?_c('div',{staticClass:"device_hint"},[_vm._v(_vm._s(_vm.hinttext))]):_vm._e(),(!_vm.isShowCheck || _vm.isShowfinishbtn == 3)?_c('button',{class:_vm.firstandmin ? 'btn_no_click' : 'btn',on:{"click":function($event){return _vm.handleTest(_vm.btnText)}}},[_vm._v(" "+_vm._s(_vm.btnText)+" ")]):_vm._e(),(!_vm.firstandmin)?_c('deviceConnect'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-papa-video"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-papa-microphone"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon"},[_c('i',{staticClass:"iconfont icon-papa-volume"})])
}]

export { render, staticRenderFns }