export default {
  // 创建白板实例
  getBoardInstance (config) {
    return new TEduBoard(config)
  },

  setBoardBgColor (color) {
    window.teduBoard.setBackgroundColor(color)
  }
}
