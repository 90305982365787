<template>
  <div class="_box">
   <!-- <div class="paint_box">
      <div id="paint_box" v-show="isShowPaintBox">
      </div>
    </div> -->
    <div class="boaed_shade" v-if="attendState === '未开始' || attendState === '已结束'"></div>
   <div class="board_box" id="board_box">
   </div>
   <div class="video-window" v-if="littleVideoWindowShow" @click="videoWindowBtn">
    <span></span>
    <span>|</span>
    <span>视频窗口</span>
   </div>
    <div class="share-video-wrap" id="share_video_wrap">
      <div class="share_video"></div>
    </div>
  </div>
</template>

<script>
  import BoardJs from './Board.js'

  export default BoardJs
</script>

<style lang="less" scoped>
  .board_box {
    &:deep(video) {
      object-fit: contain !important;
    }
  }

  .boaed_shade {
    display: inline-block;
    width: calc((100vh - 200px) * 1.33);
    height: calc(100vh - 200px);
    background: #fff;
    position: relative;
    top: 10%;
    left: 0;
  }

  .video-window {
    padding: 5px;
    z-index: 999;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 2%;
    right: 23%;
    width: 99px;
    height: 18px;
    font-size: 12px;
    color: rgba(0,0,0,0.6);
    background-color: #fff;
    border-radius: 4px;
    span {
      &:first-child {
        width: 14px;
        height: 6px;
        border: 1px solid #000;
        border-top: 5px solid #000;
        border-radius: 2px;
      }
    }
    &:hover {
      cursor: pointer;
    }
  }

  .iconfont {
    font-size: 12px !important;
  }

  .iconfont:before {
    margin-right: 0 !important ;
  } 

    
  ._box {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    & > .paint_box {
      width: 96%;
      height: 100%;
      margin: 0 2%;
      position: relative;
      display: flex;
      align-items: flex-end;
      #paint_box{
        width: 96%;
        height: 87%;
        margin: 4% 2%;
        position: relative;
        overflow: hidden;
      }
    }

    & > .share-video-wrap {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      /*background: #000;*/

      .share_video{
        width: 100%;
        height: 87%;
        div{
          video{
            left: 0;
          }
        }
      }

      /deep/.share_video {
        width: 100%;
        height: 87%;

        /deep/ div {
          video {
            left: 0;
            object-fit: contain;
          }
        }
      }
    }
  }

</style>
