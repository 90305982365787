<template>
  <div class="online-list-page">
    <div class="user-list">
      <div class="user" v-for="user in userList" :key="user.userID">
        <div class="user-desc">
          <div class="user-avatar">
            <img v-lazy='user.avatar||defaultAvatar' alt="">
          </div>
          <div class="user-name">
            {{user.nick||user.userID}}
          </div>
          <div class="user-tag" v-if="user.role === 'Admin'">教师</div>
          <div class="user-tag" v-if="user.role === 'assistant'">助教</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import OnlineList from './onlineList.js'

  export default OnlineList
</script>

<style lang="less" scoped>
  .online-list-page {
    width: 100%;
    height: 100%;

    & > .user-list {
      margin-top: 6px;
      height: 100%;
      overflow: auto;

      & > .user {
        width: 100%;
        height: 38px;
        box-sizing: border-box;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        & > .user-desc {
          display: flex;
          align-items: center;

          & > .user-avatar {
            font-size: 0;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            overflow: hidden;

            & > img {
              width: 100%;
            }
          }

          & > .user-name {
            font-size: 14px;
            color: #555;
            font-weight: 500;
            margin: 0 8px 0 12px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 10em;
            text-align: left;
          }

          & > .user-tag {
            width: 44px;
            height: 22px;
            text-align: center;
            font-size: 14px;
            line-height: 22px;
            border-radius: 2px;
            background: #dfeee8;
            color: #29D087;
          }
        }

        & > .action {
          & > .iconfont {
            font-size: 22px;
            color: #777;
            cursor: pointer;
          }

          & > .active {
            color: #29D087;
          }
        }
      }
    }
  }
</style>
