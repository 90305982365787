<template>
  <div class="devicetest">
    <i class="iconfont icon-papa-close-small-copy" @click="close"></i>
    <div class="test">
      设备检测
      <i
        class="iconfont icon-papa-renovate"
        @click="renovate"
        :class="rotateRenovate ? 'renovate' : ''"
      ></i>
    </div>

    <div class="facilitys">
      <div class="camera">
        <div style="height: 483px">
          <div class="title_icon">
            <i class="iconfont icon-papa-loading3" v-if="firstandmin"></i>
            <i
              v-else
              class="iconfont"
              :class="
                deviceState.hasCameraConnect && checkdevices.camera
                  ? 'icon-papa-choose2'
                  : 'icon-papa-fasongshibai'
              "
            ></i>
          </div>
          <div class="icon">
            <i class="iconfont icon-papa-video"></i>
          </div>
          <div class="title_icon">摄像头</div>
          <div>
            <el-select size="small" v-model="activeCameraDeviceId" @change="CameraDeviceChangeFn">
              <el-option
                v-for="(item, index) in cameraList"
                :key="index"
                :value="item.deviceId"
                :label="item.label"
              >
                {{ item.label }}
              </el-option>
            </el-select>
          </div>
          <div class="hint_title">您必须授权趴趴教育访问摄像头，才能预览</div>
          <div class="cameraDetector" v-if="!firstandmin">
            <CameraDetector
              :activeCameraDeviceId="activeCameraDeviceId"
            ></CameraDetector>
          </div>
        </div>
        <div v-if="!isShowCheckCamera">
          <devicecheck
            v-if="isShowCheck"
            @checkTest="checkTest($event, 'camera')"
          >
            <template v-slot:title> 是否可以清楚的看到自己? </template>
          </devicecheck>
        </div>
      </div>

      <div class="microphone">
        <div style="height: 483px">
          <div class="title_icon">
            <i class="iconfont icon-papa-loading3" v-if="firstandmin"></i>

            <i
              v-else
              class="iconfont"
              :class="
                deviceState.hasMicrophoneConnect && checkdevices.microphone
                  ? 'icon-papa-choose2'
                  : 'icon-papa-fasongshibai'
              "
            ></i>
          </div>
          <div class="icon">
            <i class="iconfont icon-papa-microphone"></i>
          </div>
          <div class="title_icon">麦克风</div>
          <div>
            <el-select size="small" v-model="activeMicDeviceId" @change="micDeviceChangeFn">
              <el-option
                v-for="(item, index) in micDeviceList"
                :key="index"
                :value="item.deviceId"
                :label="item.label"
              >
                {{ item.label }}
              </el-option>
            </el-select>
          </div>
          <div class="hint_title">
            麦克风出问题了嘛？调节输入音量，随便说点什么搞笑的话试试
          </div>
          <div>
            <MicDetector
              :activeMicDeviceId="activeMicDeviceId"
              v-if="!firstandmin"
            ></MicDetector>
          </div>
        </div>
        <div v-if="!isShowCheckMicrophone">
          <devicecheck
            v-if="isShowCheck"
            @checkTest="checkTest($event, 'microphone')"
          >
            <template v-slot:title> 是否可以看到音量图标跳动? </template>
          </devicecheck>
        </div>
      </div>

      <div class="trumpet">
        <div style="height: 483px">
          <div class="title_icon">
            <i class="iconfont icon-papa-loading3" v-if="firstandmin"></i>

            <i
              v-else
              class="iconfont"
              :class="
                deviceState.hasSpeakerConnect && checkdevices.trumpet
                  ? 'icon-papa-choose2'
                  : 'icon-papa-fasongshibai'
              "
            ></i>
          </div>

          <div class="icon">
            <i class="iconfont icon-papa-volume"></i>
          </div>
          <div class="title_icon">扬声器</div>
          <div>
            <el-select size="small" v-model="activeSpeakerDeviceId" @change="SpeakerDeviceChangeFn">
              <el-option
                v-for="(item, index) in speakerList"
                :key="index"
                :value="item.deviceId"
                :label="item.label"
              >
                {{ item.label }}
              </el-option>
            </el-select>
          </div>
          <div class="hint_title">请调节输出音量，点击播放下方的音频试试</div>
          <div>
            <SpeakerDetector
              v-if="!firstandmin"
              :activeSpeakerDeviceId="activeSpeakerDeviceId"
            ></SpeakerDetector>
          </div>
        </div>
        <div v-if="!isShowCheckTrumpet">
          <devicecheck
            v-if="isShowCheck"
            @checkTest="checkTest($event, 'trumpet')"
          >
            <template v-slot:title>是否可以听到声音？</template>
          </devicecheck>
        </div>
      </div>
    </div>
    <div class="device_hint" v-if="!isShowCheck">{{ hinttext }}</div>
    <button
      v-if="!isShowCheck || isShowfinishbtn == 3"
      @click="handleTest(btnText)"
      :class="firstandmin ? 'btn_no_click' : 'btn'"
    >
      {{ btnText }}
    </button>

    <deviceConnect v-if="!firstandmin"></deviceConnect>
  </div>
</template>

<script>
import TRTC from "trtc-js-sdk";
import EXT_RTC from "@/views/Live/ext/rtc.js";
import CameraDetector from "../../modules/detection/components/cameraDetector.vue";
import MicDetector from "../detection/components/micDetector.vue";
import SpeakerDetector from "../detection/components/speakerDetector.vue";
import deviceConnect from "../detection/components/deviceConnect.vue";
import devicecheck from "./components/devicecheck.vue";
import { mapActions, mapState } from "vuex";
export default {
  name: "",
  components: {
    CameraDetector,
    MicDetector,
    SpeakerDetector,
    deviceConnect,
    devicecheck,
  },
  props: {},
  data() {
    return {
      activeCameraDeviceId: "",
      activeSpeakerDeviceId: "",
      activeMicDeviceId: "",
      cameraList: [],
      micDeviceList: [],
      speakerList: [],
      localStream: null,
      rotateRenovate: false,
      firstandmin: true,
      isShowCheck: false,
      isShowCheckCamera: false,
      isShowCheckMicrophone: false,
      isShowCheckTrumpet: false,
      isShowfinishbtn: 0,
      checkdevices: {
        camera: "1",
        microphone: "1",
        trumpet: "1",
      },
      btnText: "开始检测",
      hinttext: "设备正在连接中，请稍后",
    };
  },
  computed: {
    ...mapState({
      remoteStream: (state) => state.rtc.remoteVideoStream,
      deviceState: (state) => state.footer.deviceState,
    }),
  },
  watch: {
    isShowfinishbtn: {
      handler(val) {
        if (val == 3) {
          this.btnText = "完成检测";
        }
      },
    },
    deviceState: {
      handler(newVal) {
        if (newVal) {
          console.log(newVal);
          this.hinttext = "请允许浏览器及网页访问";
          if (!newVal.hasCameraConnect) {
            this.hinttext = this.hinttext + "摄像头 ";
            this.btnText = "重新检测";
          } else if (!newVal.hasMicrophoneConnect) {
            this.hinttext = this.hinttext + "麦克风 ";
            this.btnText = "重新检测";
          } else if (!newVal.hasSpeakerConnect) {
            this.hinttext = this.hinttext + "扬声器";
            this.btnText = "重新检测";
          } else {
            this.hinttext = "设备连接成功，请开始设备检测";
            this.btnText = "开始检测";
          }
        }
      },
    },
  },
  created() {},
  mounted() {
    this.getMicDeviceList();
    this.getSpeakerList();
    this.getCamerasList();
    this.setTimeHintText();
    if(window.innerHeight < 800) {
      document.querySelector('.devicetest').style.transform = 'scale(0.9)'
    }else if(window.innerHeight < 700) {
      document.querySelector('.devicetest').style.transform = 'scale(0.8)'
    }else if(window.innerHeight < 600) {
      document.querySelector('.devicetest').style.transform = 'scale(0.7)'
    }
  },
  methods: {
    renovate() {
      this.rotateRenovate = true;
      this.getMicDeviceList();
      this.getSpeakerList();
      this.getCamerasList();
      setTimeout(() => {
        this.rotateRenovate = false;
      }, 1000);
    },
    // 获取摄像头设备
    getCamerasList() {
      EXT_RTC.getCameras().then((res) => {
        console.log(res);
        let olddeviceIndex = res.findIndex((device) => {
          return device.deviceId === this.activeCameraDeviceId;
        });
        this.activeCameraDeviceId =
          olddeviceIndex < 0 ? res[0].deviceId : this.activeCameraDeviceId;
        this.cameraList = res;
      });
    },

    // 获取麦克风设备
    getMicDeviceList() {
      EXT_RTC.getDevices().then((res) => {
        console.log(res);
        let olddeviceIndex = res.findIndex((device) => {
          return device.deviceId === this.activeCameraDeviceId;
        });
        this.activeMicDeviceId =
          olddeviceIndex < 0 ? res[0].deviceId : this.activeMicDeviceId;
        this.micDeviceList = res;
      });
    },

    // 获取扬声器设备
    getSpeakerList() {
      TRTC.getSpeakers().then((res) => {
        let olddeviceIndex = res.findIndex((device) => {
          return device.deviceId === this.activeSpeakerDeviceId;
        });
        this.activeSpeakerDeviceId =
          olddeviceIndex < 0 ? res[0].deviceId : this.activeSpeakerDeviceId;
          
        this.speakerList = res;
      });
    },
    close() {
      this.$emit("closeDeviceTest");
    },
    setTimeHintText() {
      setTimeout(() => {
        this.firstandmin = false;
      }, 1000);
    },
    handleTest(text) {
      console.log(text);
      if (text == "重新检测") {
        this.firstandmin = true;
        this.setTimeHintText();
      } else if (text == "开始检测") {
        this.isShowCheck = true;
      } else if (text == "完成检测") {
        this.setTestStatus(true);
        localStorage.setItem("isTestDevice", false);
        this.close();
      }
      // this.hinttext = "设备正在连接中，请稍后";
    },
    checkTest(isOk, device) {
      if (device === "camera") {
        this.checkdevices.camera = isOk;
        this.isShowCheckCamera = true;
        this.isShowfinishbtn++;
      }
      if (device === "microphone") {
        this.checkdevices.microphone = isOk;
        this.isShowCheckMicrophone = true;
        this.isShowfinishbtn++;
      }
      if (device === "trumpet") {
        this.checkdevices.trumpet = isOk;
        this.isShowCheckTrumpet = true;
        this.isShowfinishbtn++;
      }
    },
    // 切换扬声器触发
    SpeakerDeviceChangeFn(e) {
      this.$emit('spreadActiveSpeakerDeviceId', e)
    },

    // 切换麦克风触发
    micDeviceChangeFn(e) {
      this.$emit('spreadActiveMicDeviceId', e)
    },

    // 摄像头触发
    CameraDeviceChangeFn(e) {
      this.$emit('spreadActiveCameraDeviceId', e)
    },
    ...mapActions(["setTestStatus"]),
  },
};
</script>

<style lang='less' scoped>
.renovate {
  //动画
  animation: rotate 1s linear infinite;
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
.devicetest {
  width: 780px;
  min-height: 664px;
  border-radius: 12px 12px 12px 12px;
  background: #ffffff;
  padding: 30px;
  box-sizing: border-box;
  .device_hint {
    text-align: center;
    margin-bottom: 20px;
  }
  .test {
    -webkit-user-select: none;
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 23px;
    color: rgba(0, 0, 0, 0.9);
    padding-bottom: 20px;
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 20px;
    .icon-papa-renovate {
      width: 20px;
      height: 20px;
      display: block;
      font-size: 20px;
      margin-left: 10px;
      cursor: pointer;
    }
  }
  .icon-papa-close-small-copy {
    position: absolute;
    right: 25px;
    top: 25px;
    font-size: 14px;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.7);
    cursor: pointer;
  }
  .facilitys {
    display: flex;
    justify-content: space-between;
    .cameraDetector {
      width: 194px;
      height: 145px;
      background-color: #696464;
    }
    .icon-papa-video,
    .icon-papa-volume,
    .icon-papa-microphone {
      width: 120px;
      font-size: 120px;
      color: #000;
    }
    .icon-papa-choose2,
    .icon-papa-fasongshibai,
    .icon-papa-loading3 {
      font-size: 25px;
      animation: rotate 10s linear infinite;
      @keyframes rotate {
        0% {
          transform: rotate(0deg);
        }
        50% {
          transform: rotate(180deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    }
    .icon-papa-choose2 {
      color: #29d087;
    }
    .icon-papa-fasongshibai {
      color: #f85251;
    }
    .camera,
    .microphone,
    .trumpet {
      //内容居中
      width: 194px;
      .icon {
        width: 120px;
        height: 120px;
        margin: 15px auto 12px;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
    .title_icon {
      font-size: 16px;
      color: rgba(0, 0, 0, 0.9);
      text-align: center;
      margin-bottom: 20px;
    }
  }
  .hint_title {
    font-size: 12px;
    color: rgba(0, 0, 0, 0.7);
    // text-align: center;
    margin: 20px 0;
    // white-space: pre-wrap;
  }
  .btn {
    display: block;
    margin: 0 auto;
    width: 144px;
    height: 48px;
    background: linear-gradient(136deg, #21dc7a 0%, #17d6a4 100%);
    border-radius: 4px 4px 4px 4px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    cursor: pointer;
  }
  .btn_no_click {
    display: block;
    margin: 0 auto;
    width: 144px;
    height: 48px;
    background: #696464;
    border-radius: 4px 4px 4px 4px;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 500;
    margin-top: 10px;
    //禁止点击
    pointer-events: none;
  }
}
</style>