<template>
  <div>
    <!-- <audio id="audio-player" src="./dome.mp3" controls></audio> -->
    <audio
      controls
      id="audio-player"
      v-show="false"
      @timeupdate.stop="updateTime"
    >
      <source src="./dome.mp3" />
    </audio>
    <div>
      <span class="demonstration">输出音量</span>
      <el-slider v-model="volume" @input="dragVolume()"></el-slider>
    </div>
    <span class="demonstration">音频播放</span>
    <div class="aduio_box">
      <i
        class="iconfont icon-papa-play1"
        @click="AudioPlayer"
        v-if="!isShowPause"
      ></i>
      <i
        class="iconfont icon-papa-pause"
        @click="AudioPause"
        v-if="isShowPause"
      ></i>
      <span class="audio_time">
        <span>{{ currentTime }}</span
        >/
        <span>{{ duration }}</span>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  props: {
    activeSpeakerDeviceId: {
      type: String,
      default: "",
    },
    audioUrl: {
      type: String,
      default:
        "https://1256993030.vod2.myqcloud.com/d520582dvodtransgzp1256993030/45f1edea3701925920950247965/v.f1010.mp3",
    },
  },
  data() {
    return {
      audioRef: null,
      audioPlayer: null,
      duration: 0,
      isShowPause: false,
      currentTime: 0,
      volume: 50,
    };
  },
  computed: {},
  watch: {
    activeSpeakerDeviceId: {
      handler(newVal) {
        this.handleSpeakerChange(newVal);
      },
      deep: true,
      immediate: true,
    },
  },
  created() {},
  mounted() {
    this.getAudioDuration();
  },
  methods: {
    //获取音频播放器
    async AudioPlayer() {
      this.isShowPause = true;
      this.audioRef = document.getElementById("audio-player");
      await this.audioRef.play();
    },
    async AudioPause() {
      this.isShowPause = false;
      this.audioRef = document.getElementById("audio-player");
      await this.audioRef.pause();
    },
    //获取音频时长
    async getAudioDuration() {
      const audio = document.getElementById("audio-player");
      while (isNaN(audio.duration) || audio.duration === Infinity) {
        await new Promise((resolve) => setTimeout(resolve, 200));
        audio.currentTime = 10000000 * Math.random();
      }
      audio.currentTime = 0;
      this.duration = this.formatSeconds(audio.duration);
    },
    //获取音频播放时间
    updateTime(e) {
      this.currentTime = this.formatSeconds(e.target.currentTime);
    },
    //拖动音量
    dragVolume() {
      const audio = document.getElementById("audio-player");
      audio.volume = this.volume / 100;
    },
    formatSeconds(time) {
      // time转化成00：00
      let minute = Math.floor(time / 60);
      let second = Math.floor(time % 60);
      if (minute < 10) {
        minute = "0" + minute;
      }
      if (second < 10) {
        second = "0" + second;
      }
      return minute + ":" + second;
    },
    async handleSpeakerChange(deviceId) {
      console.log("deviceId", deviceId);
      this.audioPlayer && (await this.audioPlayer.setSinkId(deviceId));
    },
  },
};
</script>

<style lang='less' scoped>
/deep/.el-slider__runway {
  height: 4px;
}
/deep/.el-slider__bar {
  height: 4px;
}
/deep/.el-slider__button {
  background-color: #29d087;
  width: 8px;
  height: 8px;
}
/deep/.el-slider__button-wrapper {
  top: -16px;
}
.demonstration {
  color: rgba(0, 0, 0, 0.9);
}
.aduio_box {
  margin-top: 8px;
}
.icon-papa-play1,
.icon-papa-pause {
  font-size: 15px;
  color: rgba(0, 0, 0, 0.9);
  cursor: pointer;
}

.audio_time {
  margin-left: 8px;
  font-size: 14px;
}
</style>