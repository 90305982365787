<template>
  <div class="im-module">
    <el-tabs v-model="activeName" stretch @tab-click="changeTab" v-if="liveMode === 2">
      <el-tab-pane label="聊天" name="chatList">
        <div slot="label">聊天 <span v-show="msgNumber-baseMsgNumber>0&&activeName!=='chatList'">({{msgNumber-baseMsgNumber}})</span>
        </div>
        <ChatList @sendMsg="sendMsg" @loadMoreMsg="loadMoreMsg"></ChatList>
      </el-tab-pane>
      <el-tab-pane label="当前在线" name="onlineList">
        <span slot="label">当前在线({{onlineNumber}})</span>

        <OnlineList></OnlineList>
      </el-tab-pane>
      <el-tab-pane name="handsList">
        <div slot="label">发言列表<span v-show="handUpNumber>0">({{handUpNumber}})</span></div>
        <HandList></HandList>
      </el-tab-pane>
    </el-tabs>
    <el-tabs v-model="privateActiveName" stretch @tab-click="changeTab" v-else>
      <el-tab-pane name="privateChatList">
        <div slot="label" v-if="currentTeacher">
          <div slot="label" class="member_status">
            <div class="student">
              <span class="member_name">我</span>
              <div v-if='true'
                   class="online_status online_status-on iconfont icon-papa-dian">在线
              </div>
              <div v-else class="online_status online_status-off iconfont icon-papa-dian">离线</div>
            </div>

            <div class="teacher">
              <span class="member_name">{{currentTeacher.name}}老师</span>
                <div v-if='remoteMemberOnLineStatus'
                   class="online_status online_status-on iconfont icon-papa-dian">在线
                </div>
                <div v-else class="online_status online_status-off iconfont icon-papa-dian">离线</div>
            </div>
          </div>
        </div>
        <div slot="label">聊天 <span v-show="msgNumber-baseMsgNumber>0&&activeName!=='chatList'">({{msgNumber-baseMsgNumber}})</span>
        </div>
        <ChatList @sendMsg="sendMsg" @loadMoreMsg="loadMoreMsg"></ChatList>
      </el-tab-pane>
    </el-tabs>
    <audio src="https://tcdn.papaen.com/assets/audio/enterInto.mp3" v-show="false" ref="enterInfoAudioRef" />
  </div>
</template>

<script>
  import IMJS from './chatroom.js'

  export default IMJS
</script>

<style lang="less" scoped>
  .im-module {
    width: 100%;
    height: 100%;

    /deep/ .member_status {
      .teacher, .student {
        box-sizing: border-box;
        width: 50%;
        padding-left: 20px;
        float: left;
        margin: 0 auto;
        display: flex;
        .member_name{
          font-size: 16px;
          color: rgba(0, 0, 0, 0.9);
          font-weight: 700;
        }
        .online_status{
          font-size: 12px;
          margin-left: 2px;
        }
        .online_status-on {
          color: @papaGreen;
        }

        .online_status-off {
          color: #ccc;
        }

      }
    }

    /deep/ .el-tabs__item {
      color: #393D3A;
    }

    /deep/ .el-tabs__header {
      margin-bottom: 0;
    }

    /deep/ .el-tabs--top {
      height: 100%;

      & > .el-tabs__content {
        width: 100%;
        height: calc(100% - 40px);

        & > .el-tab-pane {
          height: 100%;
        }
      }
    }
  }
</style>
