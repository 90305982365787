import ChatList from './chatList/chatList.vue'
import HandList from './handList/handList.vue'
import OnlineList from './onlineList/onlineList.vue'
import EXT_IM from '@/views/Live/ext/im'
import { mapActions, mapState } from 'vuex'
import { formatMoment } from '@/utils/utils'

export default {
  data () {
    return {
      activeName: 'chatList',
      privateActiveName: 'privateChatList',
      baseMsgNumber: 0,
      isShowTraining: false
    }
  },
  mounted () {
    this.isShowTrainingFn()
  },
  computed: {
    ...mapState({
      tic: state => state.live.tic,
      tim: state => state.im.tim,
      userId: state => state.user.authorization.sign,
      onlineNumber: state => state.live.userList.length,
      roomID: state => state.live.roomID,
      handUpNumber: state => state.live.handUpList.length,
      msgNumber: state => state.live.msgs.length,
      liveMode: state => state.live.liveMode,
      currentTeacher: state => state.live.currentTeacher,
      classStatus: state => state.live.classStatus,
      avatar: state=> state.user.userInfo.avatar,
      remoteMemberOnLineStatus (state) {
        let status = false
        if (this.classStatus === 1) {
          if (state.rtc.teacherStream) {
            status = true
          } else {
            setTimeout(() => {
              if (state.rtc.teacherStream) {
                return true
              } else {
                return false
              }
            },10000)
            return true
          }
        } else {
          let privateUserAccount = this.currentTeacher.uuid
          state.live.userList.forEach(user => {
            if (user.userID === privateUserAccount) {
              status = true
            }
          })

        }
        return status
      },
      isOnLone: state => state.live.isOnLone
    })
  },
  methods: {
    changeTab () {
    },
    sendMsg (text) {
      if (!this.roomID) {
        this.$message.error('发送群消息时，房间号为空')
        return
      }
      let nick = ''
      let account = this.userId
      let avatar = this.avatar
      let identity = ''
      EXT_IM.handleGroupTextMsg(text).then((textCon) => {
        console.log(textCon)
        // 网络慢的话  新发的消息不会吧立刻展示  所以先消息上墙
        this.showMessageInBox({nick, account, avatar, identity}, textCon, 'chat', 1).then(() => {
          if (account === 'personal') {
            return
          }
          EXT_IM.sendTextMessage(text, this.roomID).then(res => {
            console.log(res)
          }).catch(err => {
            console.log(err)
          })
        })
      })
    },

    //在聊天框中显示聊天消息
    showMessageInBox (user, text, type = 'chat', status) {
      let time = (new Date().getTime() / 1000).toFixed(0)
      return new Promise((resolve, reject) => {
        let account = user.nick || user.account
        if (user.account === this.userId) {
          account = 'personal'
        }
        this.addMsg({
          send: account,
          content: text,
          type: type,
          avatar: user.avatar,
          identity: user.identity,
          status: status,
          time: time
        })
        this.$nextTick(() => {
          let msgbox = document.querySelector('#msg_box')
          msgbox.scrollTop = msgbox.scrollHeight
          resolve()
        })
      })

    },

    loadMoreMsg () {
      console.log('chatroom')
      this.$emit('loadMoreMsg')
    },
    
    isShowTrainingFn() {
      if(this.$route.fullPath.indexOf('/training/') > -1) {
        this.isShowTraining = true
      }else {
        this.isShowTraining - false
      }
    },
    formatMoment,
    ...mapActions([
      'addMsg'
    ])
  },
  components: {
    ChatList,
    HandList,
    OnlineList
  },
  watch: {
    activeName (oldVal, newVal) {
      if (oldVal === 'chatList' && newVal !== 'chatList') {
        this.baseMsgNumber = this.msgNumber
      }
      if (newVal === 'chatList') {
        this.baseMsgNumber = this.msgNumber
      }
    },
    remoteMemberOnLineStatus (newVal, oldVal) {
      if (!newVal) {
        if (this.classStatus === 1 && this.liveMode === 1) {
          setTimeout(() => {
            if (!this.remoteMemberOnLineStatus) {
              this.$emit('showMessageInBox', 'system', this.currentTeacher.name + '老师退出房间', 'notify')
              this.$message.error(`${this.currentTeacher.name}退出了教室~`)
            }
          }, 3000)
        }
      }else {
        //教师进入直播间
        this.$refs.enterInfoAudioRef.play()
      }
    }
  }
}
