import EXT_IM from '@/views/Live/ext/im'
import * as CustomMsgType from '../constants/customMsgType.js'
import EXT_RTC from '@/views/Live/ext/rtc';
import constant from '@/utils/constant';

export const imMixin = {
  methods: {
    /************ IM 事件监听*********************/
    imEventListener(tim) {
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      tim.on(TIM.EVENT.SDK_READY, this.onReadyStateUpdate)
      // SDK NOT READT
      tim.on(TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate)
      // 被踢出
      tim.on(TIM.EVENT.KICKED_OUT, this.onKickOut)
      // SDK内部出错
      tim.on(TIM.EVENT.ERROR, this.onError)
      // 收到新消息
      tim.on(TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage, this)
      // 会话列表更新
      tim.on(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onUpdateConversationList)
      // 群组列表更新
      tim.on(TIM.EVENT.GROUP_LIST_UPDATED, this.onUpdateGroupList)
      // 网络监测
      tim.on(TIM.EVENT.NET_STATE_CHANGE, this.onNetStateChange)
      // 已读回执
      tim.on(TIM.EVENT.MESSAGE_READ_BY_PEER, this.onMessageReadByPeer)
    },
    imRemoveEventListener(){
      let tim = window.tim
      // 登录成功后会触发 SDK_READY 事件，该事件触发后，可正常使用 SDK 接口
      tim.off(TIM.EVENT.SDK_READY, this.onReadyStateUpdate)
      // SDK NOT READT
      tim.off(TIM.EVENT.SDK_NOT_READY, this.onReadyStateUpdate)
      // 被踢出
      tim.off(TIM.EVENT.KICKED_OUT, this.onKickOut)
      // SDK内部出错
      tim.off(TIM.EVENT.ERROR, this.onError)
      // 收到新消息
      tim.off(TIM.EVENT.MESSAGE_RECEIVED, this.onReceiveMessage, this)
      // 会话列表更新
      tim.off(TIM.EVENT.CONVERSATION_LIST_UPDATED, this.onUpdateConversationList)
      // 群组列表更新
      tim.off(TIM.EVENT.GROUP_LIST_UPDATED, this.onUpdateGroupList)
      // 网络监测
      tim.off(TIM.EVENT.NET_STATE_CHANGE, this.onNetStateChange)
      // 已读回执
      tim.off(TIM.EVENT.MESSAGE_READ_BY_PEER, this.onMessageReadByPeer)
    },
    onReadyStateUpdate({name}) {
      console.log(name);
      const isSDKReady = name === TIM.EVENT.SDK_READY ? true : false
      this.setImSdkStatus(isSDKReady)
      return isSDKReady
    },
    onReceiveMessage({data: messageList}) {
      let that = this
      messageList.forEach(msg => {
        console.log(msg)
        // 会话类型
        let conversationType = msg.conversationType
        switch (conversationType) {
          case TIM.TYPES.CONV_C2C:
            this.handleC2CMsg(msg)
            break
          case TIM.TYPES.CONV_GROUP:
            this.handleGroupMsg(msg)
            break
          case TIM.TYPES.CONV_SYSTEM:
            if(msg.to !== that.roomID + '') return
            this.handleSystemMsg(msg)

        }
      })
    },
    onKickOut(event) {
      console.log('被提出房间', event)
      window.close()
    },
    onUpdateGroupList() {
    },
    onNetStateChange() {
    },
    onUpdateConversationList() {
    },
    onMessageReadByPeer() {
    },
    onError() {
    },
    handleGroupMsg(msg) {
      if(msg.to !== this.roomID + '') return
      let msgType = msg.type
      switch (msgType) {
        case TIM.TYPES.MSG_TEXT:
          EXT_IM.handleTextMsg(msg).then(msgCon => {
            this.handleMsgToBox(msg, msgCon, 'chat')
          })
          break
        case TIM.TYPES.MSG_GRP_TIP:
          this.handleGroupTipMsg(msg)
          break
        case TIM.TYPES.MSG_CUSTOM:
          this.handleCustomMsg(msg)
          break
        case TIM.TYPES.MSG_IMAGE:
          EXT_IM.handleImageMsgToHtml(msg.payload).then(msgCon => {
            this.handleMsgToBox(msg, msgCon, 'chat')
          })
          break
        default:
          console.log('未知自定义消息')
          break
      }
    },
    handleSystemMsg(msg) {
      let msgType = msg.payload.operationType
      switch (msgType) {
        case 1:    // 用户申请加群
          console.log('用户加群')
          break
        case 4:   // 用户被踢出群  处理为下课
          if (msg.payload.groupProfile.groupID === this.roomID) {
            this.handleClassOver()
          }
          break

        default:
          console.log('系统通知，类型：', msgType)


      }
    },
    handleC2CMsg(msg) {
      let msgType = msg.type
      switch (msgType) {
        case TIM.TYPES.MSG_TEXT:
          EXT_IM.handleTextMsg(msg).then(msgCon => {
            this.handleMsgToBox(msg, msgCon)
          })
          break
        case TIM.TYPES.MSG_CUSTOM:
          this.handleCustomMsg(msg)
          break

      }

    },
    // 解析群提示消息
    handleGroupTipMsg(msg) {
      let msgType = msg.payload.operationType
      switch (msgType) {
        case TIM.TYPES.GRP_TIP_MBR_JOIN:
          this.handleMemberJoinLeaveRoom(msg, 'join')
          break
        case TIM.TYPES.GRP_TIP_MBR_QUIT:
          this.handleMemberJoinLeaveRoom(msg, 'leave')
          break
        case TIM.TYPES.GRP_TIP_MBR_PROFILE_UPDATED:
          msg.payload.memberList.forEach(ele => {
            if (ele.userID === this.account.userID) {
              if (ele.muteTime > 0) {
                this.setUserMute(true)
              } else {
                this.setUserMute(false)
              }
            }
          })
          break
        case TIM.TYPES.GRP_TIP_GRP_PROFILE_UPDATED:
          this.showMessageInBox(msg.fromAccount, '群公告有更新', 'notify')
          EXT_IM.getGroupInfo(this.tim, this.roomID).then(groupInfo => {
            groupInfo = groupInfo.data.group
            this.setGroupInfo(groupInfo)
          })
          break
        default:
          console.log('群提示消息：类型：', msgType)
          break
      }
    },
    handleMsgToBox(msg, msgCon, type) {
      this.addMsg(this.handleFormatMsg(msg, msgCon, type))
    },
    handleFormatMsg(msg, msgCon, type) {
      return {
        send: msg.nick,
        content: msgCon,
        type: type,
        avatar: msg.avatar,
        identity: '',
        status: status
      }
    },
    handleMemberJoinLeaveRoom(msg, type) {
      // 是否自己  是否 录制账号  是否1v1
      let memberId = msg.payload.operatorID
      if (memberId.indexOf(this.userId) > -1) return
      if (msg.payload.operatorID.indexOf('tic_re') > -1) return
      if (this.liveMode === this.constant.LIVE_MODE.PRIVATE) {
        if (memberId !== this.currentTeacher.uuid) return
        let msgCon = `${this.currentTeacher.name}老师${type === 'join' ? '进入' : '退出'}房间`
        this.handleMsgToBox(msg, msgCon, 'notify')
        this.$message.success(msgCon)
      }

      EXT_IM.getGroupMemberList(this.roomID, 100, 0).then(memberList => {
        this.setUserList(memberList)
      })
    },
    handleClassOver() {
      this.$alert('该课程已结束', '提示', {
        confirmButtonText: '确定',
        callback: () => {
          window.close()
        }
      })
    },
    handleCustomMsg(msg) {
      let msgCon = JSON.parse(msg.payload.data)
      let customType = msgCon.type || msg.payload.extension
      switch (customType) {
        case CustomMsgType.C_G_HAND_UP_USERS:
          if (msgCon.uids.length === 0) {
            this.setUserHandStatus({userID: 'none', handUp: false})
            this.setUserConnectStatus({userID: 'none', isConnected: false})
            this.setStudentStream([])
            this.setIsConnect(false)
          } else {
            this.setUserHandStatus({userID: 'none', handUp: false})
            // this.setUserConnectStatus({userID: 'none', isConnected: false})
            msgCon.uids.forEach(id => {
              this.setUserHandStatus({userID: id, handUp: true})
            })
          }
          break
        case CustomMsgType.C_G_CONNECT_USER:
          if (this.liveMode === constant.LIVE_MODE.CLASS) {
            if (msgCon.uids.length === 0) {
              this.setIsConnect(false)
              this.setStudentStream([])
              let isPublingLocal = sessionStorage.isPublishedLocalStream && JSON.parse(sessionStorage.isPublishedLocalStream)
              if (this.localStream && isPublingLocal) {
                EXT_RTC.stopPush(this.trtcClient, this.localStream)
              }
              this.setUserConnectStatus({userID: 'none', isConnected: false})
            }
            msgCon.uids.forEach(id => {
              this.setUserConnectStatus({userID: id, isConnected: true})
            })
          }
          break
        case CustomMsgType.GROUP_CLASS_BEGIN:
          // 收到上课通知
          // 1v1
          if (this.liveMode === this.constant.LIVE_MODE.PRIVATE || this.liveMode === this.constant.LIVE_MODE.TRAINING) {
            this.setMicStatus(1)
            if (!this.localStream) {
              this.joinRTCRoom() // todo
            }
          } else {
            //班课
            if (this.localStream) {
              let msg = {
                data: CustomMsgType.MsgUserHandDown,
                type: 'c2c',
                targetId: this.currentTeacher.uuid
              }
              EXT_IM.sendCustomMessage(msg)
                .then(() => {
                  let isPublingLocal = sessionStorage.isPublishedLocalStream && JSON.parse(sessionStorage.isPublishedLocalStream)

                  if (this.localStream && isPublingLocal) {
                    EXT_RTC.stopPush(this.trtcClient, this.localStream)
                  }
                  this.setIsConnect(false)
                  this.setMicStatus(2)
                })
            } else {
              this.joinRTCRoom()
            }
          }
          sessionStorage.classStatus = 1
          this.setClassStatus(1)
          break
        case CustomMsgType.GROUP_CLASS_OVER:
          this.setClassStatus(2)
          sessionStorage.classStatus = 2
          if (this.liveMode === this.constant.LIVE_MODE.PRIVATE) {
            if (this.localStream) {
              const videoTrack = this.localStream.getVideoTrack()
              if (videoTrack) {
                videoTrack.stop()
                this.localStream.removeTrack(videoTrack)
              }
              this.localStream.close()
              EXT_RTC.stopPush(this.trtcClient, this.localStream)
            }
            this.setLocalStream('')
            // this.trtcClient.unsubscribe(this.teacherStream)
            this.trtcClient.leave()
              .then(()=>{
                console.log('退房成功')
              })
          }
          this.clearLists()
          this.setIsConnect(false)
          this.setCameraStatus(0)
          EXT_IM.getMemberList(this.roomID, 100, 0).then(res => {
            let list = res.data.memberList
            this.setUserList(list)
          })

          break
        case CustomMsgType.C2C_USER_HAND_UP:
          this.handleUserHandUp(msg.from)
          break
        case CustomMsgType.C2C_USER_HAND_DOWN:
          this.handleUserHandDown(msg.from)
          break
        case CustomMsgType.C2C_USER_ENTER:
          this.setClassStatus(1)
          this.setMicStatus(1)
          if (msgCon.actorsUids.length > 0) {
            msgCon.actorsUids.forEach(ele => {
              this.setUserConnectStatus({userID: ele, isConnected: true})
            })
            msgCon.raiseUids.forEach(ele => {
              if (ele === msgCon.actorsUids[0]) {
              } else {
                this.setUserHandStatus({userID: ele, handUp: true})
              }
            })
          } else {
            if (msgCon.raiseUids.length > 0) {
              msgCon.raiseUids.forEach(ele => {
                this.setUserHandStatus({userID: ele, handUp: true})
              })
            }
          }
          if (this.liveMode === this.constant.LIVE_MODE.PRIVATE) {
            if (!this.localStream) {
              if (this.isVideoCall) {
                this.joinRTCRoom() // todo
              } else {
                this.joinRTCRoom()
                  .then(()=>{
                    this.startRTC()
                  })
              }
            }
          }else{
            this.joinRTCRoom()
          }
            // else{
          //
          // }
          sessionStorage.classStatus = 1
          break
        case CustomMsgType.C_G_OPEN_SHARE:
          if (msgCon.status === 'Off') {
            this.setIsShowPaintBox(true)
          } else {
            this.setIsShowPaintBox(false)
          }

          break
        case CustomMsgType.C_G_OPEN_CAMERA:
          // this.setCameraStatus(1)
          // this.setShareStatus(0)
          break
        case CustomMsgType.GROUP_MUTE:
          this.setGroupMute(msgCon.status)
          break
        case CustomMsgType.C2C_OWNER_ALLOW_CONNECT:
          this.setMicStatus(2)
          break
        case 'TXWhiteBoardExt':
          this.addSyncData(msgCon)
          break
        default:
          console.log('自定义消息', msg)
          this.customInfo = msg
          this.customEvent()
          if(JSON.parse(msg.payload.data).type === CustomMsgType.C_G_ONLINE) {
            console.log(msg)
            this.handleOnLine({...msg, onlinePrke: Date.now()})
          }
          break
      }

    },
    handleUserHandUp(user) {
      this.setUserHandStatus({userID: user, handUp: true})
      let msgHands = {
        type: 'group',
        data: CustomMsgType.MsgHandUpUserList()
      }
      EXT_IM.sendCustomMsg(msgHands)
    },
    handleUserHandDown(user) {
      this.setUserHandStatus({userID: user, handUp: false})
      this.setUserConnectStatus({userID: user, isConnected: false})
      let msgHandDown = {
        type: 'group',
        data: CustomMsgType.MsgHandUpUserList()
      }
      let msgConnect = {
        type: 'group',
        data: CustomMsgType.MsgConnectUser()
      }
      EXT_IM.sendCustomMsg(msgConnect)
      EXT_IM.sendCustomMsg(msgHandDown)

    },
    handleGetUserEnter(msg) {
      let msgCon = {
        type: 'c2c',
        targetId: msg.from,
        data: CustomMsgType.MsgUserEnter()
      }
      if (this.classStatus === 1) {
        EXT_IM.sendCustomMsg(msgCon)
      }
    },
    addSyncData(data) {
      this.teduBoard.addSyncData(data)
    },

  }
}
