import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      defaultAvatar: require('@/assets/img/common/avatar.png')
    }
  },
  computed: {
    ...mapState({
      handUpList: state => state.live.handUpList,
    })
  },
  methods: {
    ...mapActions([
      'setHandUpList'
    ])
  },
  watch: {}
}
