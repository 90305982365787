import {MsgOpenCamera} from '../../constants/customMsgType'
import {mapState, mapActions} from 'vuex'
import EXT_RTC from '../../ext/rtc.js'
import EXT_IM from '../../ext/im'
import {Message, MessageBox} from 'element-ui'
import * as Util from '@/utils/utils'
import {rtcMixin} from '@/views/Live/mixins/rtcMixin';
import {imMixin} from '@/views/Live/mixins/imMixin';
import { exitRoom } from '@/api/live/live'
export default {
  mixins: [rtcMixin, imMixin],

  data() {
    return {
      isShowMicTip: true,
      isShowCameraTip: true,
      isShowVoiceTip: true,
      voiceNumber: 100,
      canClickVolume: true,
      speakerList: [],
      activeSpeakerDeviceId: '',
      isShowSettingPanel: false,
      micDeviceList: [],
      activeMicDeviceId: '',
      activeCamerasId: '',
      micCamerasList: []
    }
  },
  computed: {
    ...mapState({
      micStatus: state => state.live.micStatus,
      videoStatus: state => state.live.videoStatus,
      voiceStatus: state => state.live.voiceStatus,
      shareStatus: state => state.live.shareStatus,
      cameraStatus: state => state.live.cameraStatus,
      isVideoCall: state => state.live.isVideoCall,
      localStream: state => state.rtc.localStream,
      classStatus: state => state.live.classStatus * 1,
      liveMode: state => state.live.liveMode,
      isConnect: state => state.live.isConnect,
      teacherStream: state => state.rtc.teacherStream,
      trtcClient: state => state.rtc.trtcClient,
      roomId: state => state.live.roomID,
      studentStream: state => state.rtc.studentStream,
      account: state => {
        return {
          userID: state.user.authorization.sign
        }
      }
    }),

    isTraining() {
      if(this.$route.fullPath.indexOf('/training/') > -1) {
        return true
      }else {
        return false
      }

    }
  },
  mounted() {
    this.getMediaAuthorize()
    this.deviceChange()
  },
  methods: {
    exitClass() {
      this.$confirm('是否退出教室？', '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        if (this.localStream) {
          const videoTrack = this.localStream.getVideoTrack()
          if (videoTrack) {
            videoTrack.stop()
            this.localStream.removeTrack(videoTrack)
          }
        }
        if(this.$route.fullPath.includes('/training/')) {
          exitRoom(this.$route.params.id)
        }
        this.$emit('quitClassroom')
      }).catch(() => {
        this.$message.info('已取消')
      })
    },

    toggleVoice(num) {
      this.isShowVoiceTip = false
      if (num === 1) {
        this.handleOpenAudio()
      } else {
        this.handleCloseAudio()
      }
    },
    handleOpenAudio() {
      if (this.canClickVolume) {
        this.canClickVolume = false
        if (this.teacherStream) {
          this.teacherStream.resume()
          this.teacherStream.unmuteAudio()
          if (this.studentStream.length > 0) {
            this.studentStream.forEach(stream => {
              stream.resume()
              stream.unmuteAudio()
            })
          }
          this.setVoiceStatus(1)
        } else {
          this.reConnectRTC()
        }
      } else {
        this.canClickVolume = true
      }
    },
    handleCloseAudio() {
      if (this.teacherStream) {
        this.teacherStream.muteAudio()
        if (this.studentStream.length > 0) {
          this.studentStream.forEach(stream => {
            stream.muteAudio()
          })
        }
      }
      this.canClickVolume = true
      this.setVoiceStatus(0)
    },
    reConnectRTC() {
      let times = 0
      let reconnectInterval = setInterval(() => {
        if (this.teacherStream) {
          this.handleOpenAudio()
          clearInterval(reconnectInterval)
        } else {
          if (times > 2) {
            clearInterval(reconnectInterval)
            Message.error('音频连接异常，请退出房间10秒后 重新尝试~')
          }
          try {
            this.trtcClient.leave()
              .then(() => {
                console.log('stringRoomId',this.roomId);
                console.log('stringRoomId',typeof this.roomId);
                return this.trtcClient.join({
                  roomId: parseInt(this.roomId)
                })
                  .then(() => {
                    this.handleOpenAudio()
                    clearInterval(reconnectInterval)
                  })
                  .catch(e => {
                    Util.papaFundeBug(this.$route.params.id, JSON.stringify(e))
                  })
                times++
              })
          } catch (e) {
            Util.papaFundeBug(this.$route.params.id, JSON.stringify(e))
            location.reload()
          }
        }
      }, 5000)
    },

    setVoice(number) {
      number = number / 100
      this.setVolumeValue(number)
      if (this.classStatus === 1) {
        let obj = null
        obj = document.querySelector('#student_video_wrapper audio')
        obj.volume = number
      }
    },
    setVoiceNumber() {
      if (this.voiceNumber < 1) {
        this.setVoiceStatus(0)
        this.teacherStream && this.teacherStream.muteAudio()
      } else {
        this.setVoiceStatus(1)
        this.teacherStream && this.teacherStream.resume()
        this.teacherStream && this.teacherStream.unmuteAudio()
      }
      this.setVoice(this.voiceNumber)
    },

    /**
     * 摄像头开关
     */
    toggleCamera(status, mode) {
      if(this.classStatus !== 1) return // 上课中才可以开关摄像头
      this.isShowCameraTip = false
      const  _this = this
      if (this.classStatus === 1 || mode) {
        if (status === 1) {  // 开启
          new Promise((res, rej) => {
            this.setCameraStatus(1)
            if (this.cameraStatus === 1) {
              res()
            } else {
              rej()
            }
          }).then(() => {
            if (_this.localStream && !_this.localStream.hasVideo()) {
              const videoStream = EXT_RTC.createRTCStream(this.account, true, false)
              videoStream.setVideoProfile('720p')
              videoStream.initialize().then(() => {
                const videoTrack = videoStream.getVideoTrack()
                this.localStream.addTrack(videoTrack).then(() => {
                }).catch((err) => {
                  console.log('addTRACK error', err)
                })
              })
            } else {
              this.startRTC(true, true)
            }
            this.setVideoStatus(1)
            if (this.classStatus) {
              let msg = {
                data: MsgOpenCamera()
              }
              EXT_IM.sendCustomMessage(msg)
            }
          }, () => {
            console.log('error')
          })

        } else if (status === 0) {   // 关闭
          const videoTrack = this.localStream.getVideoTrack()
          if(videoTrack){
            this.localStream.removeTrack(videoTrack).then(()=>{
              videoTrack.stop()
            })
          }
          this.setVideoStatus(0)
          this.setCameraStatus(0)
          console.log('关闭摄像头并停止推流')
        } else {
        }
      } else {
        this.setCameraStatus(status)
      }

    },

    /**
     * 麦克风开关
     */
    toggleMic(status) {
      this.isShowMicTip = false
      this.setMicStatus(status)
      if (this.classStatus === 1) {
        if (this.localStream) {
          status === 1 ? this.localStream.unmuteAudio() : this.localStream.muteAudio()
        } else {
          this.startRTC()
        }
      }
    },
    getMicDeviceList() {
      EXT_RTC.getDevices().then(res => {
        let olddeviceIndex = res.findIndex(device => {
          return device.deviceId === this.activeMicDeviceId
        })
        this.activeMicDeviceId = olddeviceIndex < 0 ? res[0].deviceId : this.activeMicDeviceId
        this.micDeviceList = res
      })
    },
    setMicDevice() {
      if (this.classStatus === 1 && this.localStream) {
        this.localStream.switchDevice('audio', this.activeMicDeviceId).then(() => {
          this.setMicStatus(1)
          this.localStream.unmuteAudio()
          // this.$message.success('声音输入设备切换成功~')
        }).catch(err => {
          console.log(err, '设备连接异常')
          this.$message.error('您选择的声音输入设备异常，请切换输入设备重新尝试')
        })
      } else {
        this.$message.error('本地音频发送异常，请退出课堂后重新进入')
      }
    },
    getSpeakerList() {
      EXT_RTC.getSpeakers().then(res => {
        let olddeviceIndex = res.findIndex(device => {
          return device.deviceId === this.activeSpeakerDeviceId
        })
        this.activeSpeakerDeviceId = olddeviceIndex < 0 ? res[0].deviceId : this.activeSpeakerDeviceId
        this.speakerList = res
      })
    },
    setSpeakerDevice() {
      if (this.classStatus === 1 && this.teacherStream) {
        this.teacherStream.setAudioOutput(this.activeSpeakerDeviceId).then(() => {
          // this.$message.success('声音输出设备切换成功~')
        }).catch(err => {
          this.$message.error('您选择的声音输出设备异常，请切换输出设备重新尝试')
        })
      } else {
        this.$message.error('音频连接异常，请与老师共同退出课堂后重新进入')
      }
    },
    getCamerasList() {
      EXT_RTC.getCameras().then(res => {
        let olddeviceIndex = res.findIndex(device => {
          return device.deviceId === this.activeCamerasId
        })
        this.activeCamerasId = olddeviceIndex < 0 ? res[0].deviceId : this.activeCamerasId
        this.micCamerasList = res
      })
    },
    setCameraDevice() {
      if (this.classStatus === 1 && this.localStream.hasVideo()) {
        this.localStream.switchDevice('video', this.activeCamerasId).then(() => {
          this.setMicStatus(1)
          this.localStream.unmuteAudio()
        }).catch(err => {
          console.log(err, '设备连接异常')
          this.$message.error('您选择的视频输入设备异常，请切换输入设备重新尝试')
        })
      } else {
        this.$message.error('当前不是上课状态或未开启摄像头~')
      }
    },

    getDeviceList() {
      this.getMicDeviceList()
      this.getSpeakerList()
      this.getCamerasList()
    },
    setDevice() {
      if (this.classStatus === 1) {
        this.setSpeakerDevice()
        this.setMicDevice()
        this.setCameraDevice()
      } else {
        Message.error('当前不是上课状态，请上课后重新尝试设置')
      }
      this.isShowSettingPanel = false
    },

    getMediaAuthorize() {
      let _this = this
      navigator.mediaDevices.getUserMedia({audio: true})
        .then(function (stream) {
          /* use the stream */
          _this.getDeviceList()

        })
        .catch(function (err) {
          console.log(JSON.stringify(err));
          MessageBox.alert('您拒绝了音视频权限，将导致无法正常上课, 请在浏览器设置中打开权限', '提示', {
            confirmButtonText: '好的',
            showClose: false,
            callback: () => {
            }
          })
        });
    },

    deviceChange() {
      let _this = this
      navigator.mediaDevices.ondevicechange = function (event) {
        _this.getDeviceList();
      }
    },

    // 打开设备检测
    openTest() {
      this.$emit('cutDeviceTest')
      this.isShowSettingPanel = false
    },
    ...mapActions([
      'setShareStatus',
      'setVideoStatus',
      'setCameraStatus',
      'setMicStatus',
      'setVoiceStatus',
      'setVolumeValue',
      'setLocalStream'
    ])
  },
  watch: {
    classStatus(value) {
      if (value === 0 || 2) {
        this.canClickVolume = true
      }
    }
  }
}
