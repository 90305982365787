import { mapState } from 'vuex'

export default {
  props: {
    liveData: {
      type: Object
    }
  },
  data() {
    return {
      hasLocalVideo: false,
      studentInfo: {}
    }
  },
  mounted() {
    this.studentInfo = JSON.parse(localStorage.getItem('user'))
  },
  methods: {
    moveErrorImg(event) {
      event.currentTarget.src = require('@/assets/img/common/avatar.png')
    }
  },
  computed: {
    ...mapState({
      liveMode: state => state.live.liveMode,
      trtcClient: state => state.rtc.trtcClient,
      localStream: state => state.rtc.localStream,
      teacherStream: state=>state.rtc.teacherStream,
    })
  },
  
  watch:{
    localStream:{
      deep: true,
      handler: function( newVal ) {
        this.hasLocalVideo = newVal.hasVideo()
      }
    }
  }
}