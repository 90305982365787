import {MessageBox} from 'element-ui';
import EXT_RTC from '@/views/Live/ext/rtc'

export const rtcMixin = {
  methods: {
    // TRTC事件
    initTRTCEvent() {
      this.trtcClient.on('stream-added', event => {
        const remoteStream = event.stream
        // 订阅远端流
        console.log('订阅远端流', remoteStream)
        let isVideoCall = this.isVideoCall
        if (isVideoCall) {
          this.trtcClient.subscribe(remoteStream)
        } else {
          this.trtcClient.subscribe(remoteStream)
        }
      })

      // 监听‘stream-removed’事件
      this.trtcClient.on('stream-removed', event => {
        const remoteStream = event.stream
        const streamType = event.stream.getType()
        const remoteUserId = remoteStream.getUserId()
        if (remoteUserId.indexOf('desktop_share_record_user_') > -1 || streamType === 'auxiliary') {
          this.setIsShowPaintBox(true)
        } else {
          let currentTeacher = JSON.parse(sessionStorage.currentTeacher)
          let teacherId = currentTeacher.uuid
          if (remoteUserId === teacherId) {
            this.setRemoteStream(null)
          }else {
            let streamArr = this.studentStream
            streamArr.forEach((stream,index)=>{
              if(stream.userID_ === remoteUserId){
                stream.splice(index,1)
              }
            })
            this.setStudentStream(streamArr)
          }
        }
        console.log('remoteStream ID: ' + remoteStream.getId() + ' has been removed')
        // 停止播放并删除相应<video>标签
        remoteStream.stop()
        if (document.getElementById(remoteStream.getId())) {
          document.getElementById(remoteStream.getId()).remove()
        }
      })

      // 监听‘stream-updated’事件
      this.trtcClient.on('stream-updated', event => {
        const remoteStream = event.stream
        console.log('remoteStream ID: ' + remoteStream.getId() + ' was updated hasAudio: ' +
          remoteStream.hasAudio() + ' hasVideo: ' + remoteStream.hasVideo())
      })

      // 监听‘stream-subscribed’事件
      this.trtcClient.on('stream-subscribed', event => {
        console.log('stream-subscribed', event)
        const remoteStream = event.stream
        const streamType = event.stream.getType()
        const remoteUserId = remoteStream.getUserId()
        // 远端流订阅成功，在HTML页面中创建一个<video>标签，假设该标签ID为‘remote-video-view’
        // 播放该远端流

        console.log(remoteUserId, streamType,'==============================')
        if (remoteUserId.indexOf('desktop_share_record_user_') > -1 || streamType === 'auxiliary') {
          let remoteVideoWrapEl = document.getElementsByClassName('share_video')[0]
          if (!remoteVideoWrapEl) {
            remoteVideoWrapEl = document.createElement('div')
            remoteVideoWrapEl.className = 'share_video'
          }
          remoteVideoWrapEl.id = remoteStream.getId()
          document.querySelector('#share_video_wrap').insertBefore(remoteVideoWrapEl, null)
          this.setIsShowPaintBox(false)
          remoteStream.play(remoteVideoWrapEl, {
            objectFit: 'contain'
          }).catch(e => {
            console.log('自动播放音视频流失败：', e)
            const eCode = e.getCode()
            if (eCode === 0x4043) {
              remoteStream.resume()
            }
          })
        } else {
          let currentTeacher = JSON.parse(sessionStorage.currentTeacher)
          if (remoteUserId === currentTeacher.uuid) {
            remoteStream.muteAudio()
            let remoteVideoWrapEl = document.getElementsByClassName('remote_video')[0]
            if (!remoteVideoWrapEl ) {
              remoteVideoWrapEl = document.createElement('div')
              remoteVideoWrapEl.className = 'remote_video'
            }
            remoteVideoWrapEl.id = remoteStream.getId()
            document.querySelector('#student_video_wrapper').insertBefore(remoteVideoWrapEl, null)
            remoteStream.play(remoteVideoWrapEl).then(() => {

            }).catch(e => {
              const eCode = e.getCode()
              console.log('自动播放音视频流失败：', e, eCode)
              if (eCode === 16451) {
                remoteStream.resume()
              }
            })

            let obj = document.querySelector('#student_video_wrapper audio')
            obj.volume = 1
            this.setRemoteStream(remoteStream)
            this.setVoiceStatus(0)
            remoteStream.muteAudio()

          } else if(remoteUserId.indexOf('tiw_record_user_') > -1) {
            console.log('白板推流')
            let boardWraper = document.createElement('div')
            boardWraper.className = 'hiddenBoard'
            boardWraper.style = 'position: absolute; top: 9%; left: 14%; height: calc(100vh - 200px); margin-right: 30px'
            boardWraper.id = remoteStream.getId()
            document.querySelector('#board_box').insertBefore(boardWraper, null)
            remoteStream.play(boardWraper)
            // this.addStudentStream(remoteStream)
          } else {
            if (parseInt(this.voiceStatus) === 0) {
              remoteStream.muteAudio()
            }
            let studentWraper = document.createElement('div')
            studentWraper.className = 'hiddenWrapper'
            studentWraper.id = remoteStream.getId()
            document.querySelector('#student_video_wrapper').insertBefore(studentWraper, null)
            remoteStream.play(studentWraper)
            this.addStudentStream(remoteStream)

          }
        }
      })

      // 网络状态
      this.trtcClient.on('connection-state-changed', event => {
        console.log('connection-state-changed:', event)
        // if (event.prevState === 'CONNECTED' && this.classStatus !==2) {
        //   MessageBox.alert('网络异常，请检查网络状态。', '提示', {
        //     confirmButtonText: '重试',
        //     callback: () => {
        //       location.reload()
        //     }
        //   })
        // }
      })

      this.trtcClient.on('peer-join', event => {
        console.log('peer-join', event)
      })

      this.trtcClient.on('peer-leave', event => {
        console.log('peer-leave', event)
        // 屏幕分享流断开 则移除屏幕分享占位
        if (event.userId.indexOf('desktop_sharing') > -1) {
            this.setIsShowPaintBox(true)
        }
        let studentStream = this.studentStream
        if (studentStream.length > 0) {
          studentStream.forEach((ele, index) => {
            if (ele.getUserId() === event.userId) {
              studentStream.splice(index, 1)
            }
          })
          this.setStudentStream(studentStream)
        }
      })

      this.trtcClient.on('mute-audio', event => {
        console.log('mute-audio', event)
      })

      this.trtcClient.on('mute-video', event => {
        console.log('mute-video', event)
      })

      this.trtcClient.on('unmute-audio', event => {
        console.log('unmute-audio', event)
      })

      this.trtcClient.on('unmute-video', event => {
        console.log('unmute-video', event)
      })

      this.trtcClient.on('error', error => {
        console.error('client error observed: ' + error)
      })
      this.trtcClient.on('client-banned', error => {
        console.error('client-banned observed: ' + error)
        // 退出刷新页面
      })

      this.trtcClient.on('network-quality', event => {
        this.setUploadQuality(event.uplinkNetworkQuality)
        this.setDownloadQuality(event.downlinkNetworkQuality)
        // console.log(`network-quality, uplinkNetworkQuality:${event.uplinkNetworkQuality}, downlinkNetworkQuality: ${event.downlinkNetworkQuality}`)
      })
    },

    // 启动推流
    startRTC() {
      const localStream = EXT_RTC.createRTCStream(this.account.userID, !!this.cameraStatus, true)
      localStream.setAudioProfile('high')
      localStream
        .initialize()
        .catch(error => {
          switch (error.name) {
            case 'NotReadableError':
              Message.error('暂时无法访问摄像头/麦克风，请确保当前没有其他应用请求访问摄像头/麦克风，并重试。')
              break
            case 'NotAllowedError':
              Message.error('当前摄像头/麦克风未授权访问， 无法进行音视频通话,请修改浏览器设置，并重试。')
              break
            case 'NotFoundError':
              Message.error('没找到可用的摄像头/麦克风。')
              break
            default:
              Message.error('由于某些未知原因导致设备无法被使用。')
              break

          }
          console.error('failed initialize localStream ' + error, error.name)
        })
        .then(() => {
          this.setLocalStream(localStream)
          return this.trtcClient.publish(localStream)
        })
        .then(()=>{
          sessionStorage.isPublishedLocalStream = true
          let localVideoWrapEl = document.getElementsByClassName('local_video')[0]
          if (!localVideoWrapEl) {
            localVideoWrapEl = document.createElement('div')
            localVideoWrapEl.className = 'local_video'
          }
          localVideoWrapEl.id = localStream.getId()
          document.querySelector('#local_video_wrapper').insertBefore(localVideoWrapEl, null)

          // 本地流播放
          localStream.play(localVideoWrapEl, {
            objectFit: 'contain',
            muted: true
          })

        })
    }
  }
}
