import { mapState } from 'vuex'

export default {
  props: {
    cutPattern: {
      type: Number
    },
    liveData: {
      type: Object
    },
    bigVideoWindowShow: {
      type: Boolean
    }
  },
  data () {
    return {
      viewType: 1,
      hasLocalVideo: false,
      isShowOff: true,
      studentInfo: {},
      isShowVideo: false
    }
  },
  computed: {
    ...mapState({
      liveMode: state => state.live.liveMode,
      trtcClient: state => state.rtc.trtcClient,
      localStream: state => state.rtc.localStream,
      teacherStream: state=>state.rtc.teacherStream,
      classStatus: state => state.live.classStatus,
    })
  },

  mounted() {
    this.studentInfo = JSON.parse(localStorage.getItem('user'))
    this.isTraining()
  },

  methods: {
    changeView () {
      this.viewType = this.viewType === 1 ? 2 : 1
    },

    offBtn() {
      this.$emit('closeBigVideo', false)
    },

    moveErrorImg(event) {
      event.currentTarget.src = require('@/assets/img/common/defaultpic-S@3x.png')
    },

    isTraining() {
      if(this.$route.fullPath.indexOf('/training/') > -1) {
        this.isShowVideo = true
      }else {
        this.isShowVideo = false
      }
    }
    
  },
  watch:{
    localStream:{
      deep: true,
      handler: function( newVal ) {
        if(newVal) {
          this.hasLocalVideo = newVal.hasVideo()
        }else {
          this.hasLocalVideo = false
        }
      }
    }
  },

  directives: {
    inserted: function(el, binding, vnode) {
      el.style.cursor = "move";
      el.onmousedown = function(e) {
        let disx = e.pageX - el.offsetLeft;
        let disy = e.pageY - el.offsetTop;
        document.onmousemove = function(e) {
          let x = e.pageX - disx;
          let y = e.pageY - disy;
          let maxX =
            document.querySelector('.live-page').clientWidth -
            parseInt(window.getComputedStyle(el).width);
          let maxY =
            document.querySelector('.live-page').clientHeight -
            parseInt(window.getComputedStyle(el).height);
          
          if (x < 0) {
            x = 0;
          } else if (x > maxX) {
            x = maxX;
          }
  
          if (y < 0) {
            y = 0;
          } else if (y > maxY) {
            y = maxY;
          }
          el.style.left = x + "px";
          el.style.top = y + "px";
        };
        document.onmouseup = function() {
          document.onmousemove = document.onmouseup = null;
        };
      };
    },
 }
}
