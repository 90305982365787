import {mapState} from 'vuex';

export default {
  data () {
    return {
      defaultAvatar: require('@/assets/img/common/avatar.png')
    }
  },
  computed: {
    ...mapState({
      userList: state => {
        let userArr = []
        state.live.userList.forEach(user=>{
          if(user.role === 'Admin'){
            userArr.unshift(user)
          }else{
            userArr.push(user)
          }
        })
        return userArr
      }
    })
  },
  methods: {
  }
}
